<template>

  <ab-flow-base-cmp :draggable="false" class="repeater-cmp" :block="block" :is_container="true">

    <template v-for="(item, k) of items" :key="k">
      <data-provider :storage-key="block?.title" :data="{item:item}">
        <ab-flow-components-renderer :items="block.children"/>
      </data-provider>
    </template>

  </ab-flow-base-cmp>

</template>

<script>
import get from 'lodash/get.js';
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {DbTable} from "../../../../../../../../../common/db/DbTable";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "RepeaterEditorCmp",
  computed: {
    items() {
      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if(node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table);
          const fields = await DbTableField.query().where("table_id", node.db_table).get();

          const defaultValues = (fields || []).reduce((acc, field) => {
            if (field?.default_value !== null && field?.default_value !== undefined) {
              acc[field.name] = field.default_value;
            }

            return acc;
          }, {});

          const testData = table?.table_data || table?.test_table_data || [];

          // check if table has data
          if(testData?.length) {
            return testData.slice(0, 20).map((item) => ({
              ...defaultValues,
              ...(Object.fromEntries(
                Object.entries(item).filter(([,value]) => value !== null && value !== undefined)
              )),
            }));
          } else if (node?.value && node?.is_test_value) {
            return this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
          }

          // Load table fields
          for(const fld of fields) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else if (node?.type === 'tree-storage' && node?.tree_storage_node) {
          const treeStorageNodes = await StorageNode.query().where({
            app_id: node.app_id,
            module_id: node.module_id,
            block_id: 'tree-storage',
          }).get();

          const path = [];

          let parentId = node.tree_storage_node;

          while (parentId) {
            const parentNode = treeStorageNodes.find(n => n.id === parentId);
            path.unshift(`${parentNode.name}${parentNode.is_array && parentId !== node.tree_storage_node ? '.0' : ''}`);
            parentId = parentNode?.parent_id;
          }

          return get(this.designer.treeStorage, path.join('.'));
        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Convert item elements to object
        items = (Array.isArray(items) ? items : []).map(el => {
          return typeof el === 'object' ? el : {value: el}
        })

        // Return list
        return items.length ? items.slice(0, 20) : [{}];
      }, [])
    }
  }
}

</script>


<style lang="scss">

.repeater-cmp {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;

  .repeater-events {
    position: absolute;
    z-index: 1;
  }

  [class*="dg-gutter-x-"] > & {
    column-gap: var(--dg-gutter-x, 0px);
  }

  [class*="dg-gutter-y-"] > & {
    row-gap: var(--dg-gutter-y, 0px);
  }
}

</style>
