<template>
  <div>
    <dynamic-string
      :multiple="true"
      v-model="dynamicString"
      :app-id="product_id"
      :module-id="module_id"
      :hint="hint"
      :block-id="block.id"
      :title="title || label"
      :parent-diagram-id="diagram_id"
      :ai-text-generator="false"
    >
      <template #actions>
        <ai-json-path-generator v-model="jsonPath" :parent-id="nodeParentId" />
      </template>
    </dynamic-string>
  </div>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import AiJsonPathGenerator from '@/components/AI/AiJsonPathGenerator.vue';

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropJsonPathEditor",

  components: {AiJsonPathGenerator, DynamicString},

  props: {
    defaultValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    parentId: {
      required: false,
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dynamicString: {
        value: "",
        bindings: {}
      }
    };
  },

  computed: {
    jsonPath: {
      get() {
        return typeof this.dynamicString === 'string' ? this.dynamicString : this.dynamicString.value;
      },
      set(value) {
        if (typeof this.dynamicString === 'string') {
          this.dynamicString = value;
        } else {
          this.dynamicString.value = value;
        }
      },
    },

    nodeParentId() {
      const [,id] = this.parentId.split(':');

      return Number(id);
    },
  },

  created() {
    // Init current value
    if(typeof this.currentValue === 'object') this.dynamicString = this.currentValue;
    if(typeof this.currentValue === 'string') this.dynamicString.value = this.currentValue;

    // Add watcher
    this.$watch('dynamicString', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },
}

</script>
