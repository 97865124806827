/**
 * Marketing keyword model
 */
import {AccessModel} from './AccessModel.js';

export class MarketingKeyword extends AccessModel {
    static entity = 'marketing_keywords'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        app_id: "int",
        keyword: "string",
        language: "string",
        country: "string"
    }


    /**
     * Functions list
     */
    async channels() {
        return {
            'app-lang-country-keywords': {
                subscribe: ({module_id, language, country}) => (`${module_id}-${language}-${country}`),
                init: async ({module_id, language, country}) => MarketingKeyword.getList(module_id, language, country),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id, language, country) {
        return this.query().where({
            module_id,
            ...(language && language !== 'any' ? {language} : {}),
            ...(country && country !== 'any' ? {country} : {})
        }).get()
    }
}
