<template>
  <div class="db-permissions">
    <div v-for="(perm, k) of Object.keys(permissions)" :key="k" class="row q-mb-xs">
      <div :class="`perm-${perm}`" class="col-3 perm flex content-center justify-center q-mr-md">{{perm}} permission</div>
      <q-input v-model.trim="permissions[perm]" label="Coma separated roles list" class="col"/>
    </div>
  </div>
</template>

<script>

export default {
  name: "DbPermissionSettings",
  props: {
    modelValue: {},
    permissionList: {
      type: Array,
      default: () => ['read', 'write', 'delete']
    }
  },
  data: () => ({
    permissions: {
    }
  }),
  created() {

    // Apply default permissions
    this.permissionList.forEach(perm => {
      this.permissions[perm] = ''
    })

    // Set initial permissions
    if(this.modelValue && typeof this.modelValue === 'object') this.permissions = this.modelValue

    // Watch for permissions changes and update model
    this.$watch('permissions', () => {
      this.$emit('update:modelValue', this.permissions)
    }, {deep: true})
  }
}

</script>

<style lang="scss">

.db-permissions {
  border:1px solid #ddd;
  padding: 10px;
  background: #fafafa;
  .perm-read { background-color: #eef; }
  .perm-write { background-color: #ffe; }
  .perm-delete { background-color: #fee; }
}


</style>
