<template>
  <div class="column full-height full-width" v-if="schema">
    <diagram-designer class="column col" v-if="schema" :external-source="schema" :styles="defaultStyles" @save="saveSchema"/>
  </div>
</template>

<script>

//import {TreeNode} from "../models/TreeNode";

import {defaultStyles} from "./defaultStyles";
import DiagramDesigner from "@/components/DiagramDesigner/Editor/DiagramDesigner.vue";
import {p3pMixins} from "@/pages/p3p/p3pMixins";

export default {
  name: "P3PDiagramEditor",
  mixins:[p3pMixins],
  components: {DiagramDesigner},
  data: () => ({
    defaultStyles,
    schema: null
  }),

  async created() {

    try {

      // Parse schema
      let schema = this.p3pNode.properties?.schema

      // Validate result
      if (schema?.type !== 'root') {
        schema = {
          type: "root"
        }
      }

      // Set schema
      this.schema = schema
    } catch (ex) {
      throw new Error(`Failed to load schema: ${ex}`)
    }
  },

  methods: {

    /**
     * Save schema
     * @param newSchema
     */
    async saveSchema(newSchema) {
      await this.p3pNode.saveProperties({
        schema: newSchema
      })
    }
  },
}

</script>

<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
}

</style>
