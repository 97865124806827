<template>

  <modal-dialog ref="moveFolder" :title="`${editFolder?.id ? 'Edit' : 'New'} folder`" persistent>
    <folder-picker
      :type="editFolder.type"
      :app_id="editFolder.app_id"
      :module_id="editFolder.module_id"
      :disabled-folders="[editFolder.id]"
      v-model="moveId"
    />

    <div class="q-mt-md">
      <q-btn
        label="Move"
        color="primary"
        :disable="typeof moveId !== 'number' || moveId === editFolder.id"
        @click="onMoveFolder"
      />
    </div>
  </modal-dialog>

  <q-form ref="componentForm" @submit="onSaveComponent" class="q-gutter-md">
    <q-input
        v-model="mutate(editFolder).name"
        label="Folder name *"
        hint="name of folder"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
    />
    <div class="row q-gutter-sm">
      <q-btn label="Save" type="submit" color="primary"/>

      <template v-if="editFolder?.id">
        <q-space/>
        <q-btn label="Delete" color="negative" @click="onDeleteFolder"/>
        <q-btn label="Move" color="primary" @click="$refs.moveFolder.show()" />
      </template>
    </div>
  </q-form>

</template>

<script>
import {Folder} from "@/../../common/db/Folder.js"
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import FolderPicker from '@/pages/workspace/folders/FolderPicker.vue';

export default {
  name: "FolderForm",
  components: {ModalDialog, FolderPicker},
  props: ['editFolder'],
  emits: ['closed', 'saved'],
  data() {
    return {
      moveId: null,
    };
  },

  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveComponent() {

      // Update or created (depends on id field existence)
      const cmp = await Folder.remote().save(this.editFolder)

      // Hide dialog
      this.$emit('closed')
      this.$emit('saved', cmp)
    },

    /**
     * Deletes the current folder after user confirmation.
     * Shows a confirmation dialog and, upon confirmation, deletes the folder.
     * Emits 'closed' event and navigates to the parent folder upon successful deletion.
     * Displays an error notification if the deletion fails.
     */
    async onDeleteFolder() {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete "${this.editFolder.name}"?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          await Folder.remote().call('folder', 'deleteFolder', {
            id: this.editFolder.id,
          });

          this.$emit('closed');

          this.$router.push({params: {folder_id: this.editFolder?.parent_id || 0}});
        } catch (e) {
          this.$q.notify({
            color: 'negative',
            message: e?.error || e?.message || e,
            position: 'top',
            timeout: 3000,
          });
        }
      })
    },

    /**
     * Validates if the folder can be moved to the selected parent folder.
     * Ensures that the folder is not moved to its own subfolder.
     *
     * @returns {Promise<boolean>} Returns true if the move is valid, otherwise false.
     */
    async validateMoveFolder() {
      if (this.moveId === 0) {
        return true;
      }

      const targetParents = [];
      let parentId = this.moveId;

      while (parentId) {
        const folder = await Folder.find(parentId);

        if (!folder?.id) {
          break;
        }

        targetParents.push(folder.id);
        parentId = folder.parent_id;
      }

      if (targetParents.includes(this.editFolder.id)) {
        this.$q.notify({
          color: 'negative',
          message: 'You cannot move a folder to its own subfolder',
          position: 'top',
          timeout: 3000,
        });

        return false;
      }

      return true;
    },

    /**
     * Moves the current folder to a new parent folder after user confirmation.
     * Shows a confirmation dialog and, upon confirmation, moves the folder.
     * Emits 'closed' event and navigates to the new parent folder upon successful move.
     * Displays an error notification if the move fails.
     */
    async onMoveFolder() {
      this.$q.dialog({
        title: 'Move confirmation',
        message: `Are you sure want to move "${this.editFolder.name}"?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          if (!await this.validateMoveFolder()) {
            return;
          }

          await Folder.remote().save({
            ...this.editFolder,
            parent_id: this.moveId || 0,
          })

          this.$emit('closed');

          this.$router.push({params: {folder_id: this.moveId || 0}});
        } catch (e) {
          this.$q.notify({
            color: 'negative',
            message: e?.error || e?.message || e,
            position: 'top',
            timeout: 3000,
          });
        }
      })
    },
  }
}

</script>
