<template>

  <div class="col column">
    <q-tabs v-model="tab">
      <q-tab name="function">Action description</q-tab>
      <q-tab name="code">Code</q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" class="col">
      <q-tab-panel name="function" class="column col">
        <q-input class="col full-height" autogrow v-model="currentValue" label="Action description" type="textarea"/>
      </q-tab-panel>

      <q-tab-panel name="code" class="col column">
        <code-editor class="col" v-model="actionCode"/>
      </q-tab-panel>
    </q-tab-panels>
    <q-separator/>

    <div class="q-mt-sm justify-end row">
      <q-btn label="Generate" flat color="primary" icon="send" @click="generateCode"/>
      <q-btn label="Save" flat color="primary" icon="save" @click="saveCode"/>
    </div>

  </div>
</template>

<script>

import CodeEditor from "@/components/Editors/CodeEditor.vue";

export default {
  name: "AIActionExecutor",
  components: {CodeEditor},
  props: {
    block: {
      type: Object,
      default: () => ({})
    },
  },
  inject: {
    module_id: {
      default: null
    },
  },
  data: () => ({
    currentValue: "",
    tab: "function",
    actionCode: ""
  }),

  /**
   * Created
   */
  created() {

    // Init value
    this.currentValue = this.block.properties?.description?.value

    // Store action code
    this.actionCode = this.block.properties?.actionCode;
  },

  methods: {

    /**
     * Save code
     */
    saveCode() {
      // Store code as block property
      this.mutate(this.block).properties.actionCode = this.actionCode;
      this.mutate(this.block).properties.description.value = this.currentValue;
    },

    /**
     * Generate code
     * @return {Promise<void>}
     */
    async generateCode() {

      // Bindings
      //const bindings = this.block.properties.description.bindings || {};

      // Replace values in currentValue of format {xxx} with actual values
      /*const promptValue = this.currentValue.replace(/{([^}]+)}/g, (match, p1) => {
        return `'${bindings[p1]?.value}'`;
      });*/

      //console.log("Prompt value", promptValue);
      //if(promptValue) return;

      // Call controller method that will generate json code according to prompt
      const code = await this.app.client.call("ai-designer", "generateAIActionCode", this.module_id, this.currentValue)

      // Store code as block property
      this.actionCode = code;
    }
  }
}

</script>
