<template>
  <q-form @submit="save" class="q-gutter-y-sm">
    {{properties}}
    <q-field readonly>
      <template v-slot:control>
        {{properties.startNodeTitle}}
      </template>
      <template v-slot:append>
        <q-btn icon="search" flat round @click="selectNode"/>
      </template>
    </q-field>

    <q-select v-model="properties.appType" map-options emit-value :options="globals.options.module_types"/>

    <q-input v-model="properties.domain" label="Domain"/>

    <q-btn type="submit" color="primary">Save</q-btn>
  </q-form>
</template>

<script>
export default {
  name: "BuilderSettings",
  inject: {
    p3p: {default: null}
  },

  data: () => ({
    properties: {},
  }),

  /**
   * On created
   */
  created() {
    // Save props
    this.properties = this.p3p.properties;
  },
  methods: {
    /**
     * Select node
     * @return {Promise<void>}
     */
    async selectNode() {

      // Select node
      const node = await this.p3p.sendAction('select-node');

      // If node selected
      if(node) {
        this.properties.startNodeTitle = node.title;
        this.properties.startNodeToken = node.accessToken;
      }
    },

    /**
     * Save
     */
    async save() {
      try {
        this.$q.loading.show()
        await this.p3p.saveProperties(this.properties)
      } catch (e) {
        console.error(e)
      }
      finally {
        this.$q.loading.hide()
      }
    }
  }
}
</script>
