<template>

  <div>
    <q-checkbox v-model="visibility.showInEditor" right-label label="Visible in editor" />
    <value-selector v-model="visibility.variable" :parent-diagram-id="diagram_id" :block-id="block.id" class="col" :app-id="product_id" :module-id="module_id"/>

    <q-separator spaced/>

    <q-select label="Value should be equal" :options="globals.options.boolValues" emit-value v-model="visibility.variableValue" map-options/>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropVisibility",
  components: {ValueSelector},

  data: () => ({
    visibility: {
      active: false,
      showInEditor: true,
      visibility: {
        variableValue: true
      },
      parentFragment: false
    }
  }),

  created() {

    // Get parent fragment
    this.parentFragment = this.canvas.getNodeParentsById(this.block.id).find(b => b.type === 'Fragment');

    // Init current value
    if(this.currentValue) this.visibility = this.currentValue

    // Add watcher
    this.$watch('visibility', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "visibility"
  }
}

</script>

<style lang="scss" scoped>

</style>
