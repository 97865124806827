import {treeHelper} from "../utils/treeHelper.js";
import {nanoid} from "nanoid";
import {StorageNode} from "./StorageNode.js";
import {dataHelper} from "../utils/dataHelper.js";
import {Localization} from './Localization.js';
import {LocalizationMessage} from './LocalizationMessage.js';
import {AccessModel} from './AccessModel.js';

/**
 * Diagram model
 */
export class Diagram extends AccessModel {
    static entity = 'diagrams'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        diagram_type: "string",
        status: "string",
        title: "string",
        alias: "string",
        description: "string",
        source: "json",
        version: "int",
        module_id: "int",
        unique_id: "string",
        folder_id: "int",
    }

    /**
     * Clears the cache for the current diagram's application.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'app-diagrams': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => Diagram.query().where({app_id}).get(),
            },
            'app-module-diagrams': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => Diagram.getList(module_id),
            },
            'diagram': {
                subscribe: ({id}) => id,
                init: async ({id}) => Diagram.find(id),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }

    /**
     * Duplicate diagram
     * @param diagram_id
     * @param app_id
     * @param module_id
     * @param fromTemplate
     * @param folder_id
     * @param newTitle
     * @return {Promise<boolean>}
     */
    static async duplicate(diagram_id, app_id, module_id, fromTemplate = false, folder_id = 0, newTitle = null) {
        if (typeof module_id === 'string') {
            module_id = Number(module_id);
        }

        // Find all widgets in the schema
        const widgetsId = new Set;
        const widgetTitles = new Map;

        // get component from the local storage
        let component = await Diagram.find(diagram_id)

        // Copy source
        const newSource = JSON.parse(JSON.stringify(component.source))

        // Links migration map
        const linksMap = {}, nodesToCopyStorage = []

        // Regenerate ids for source
        treeHelper.traverseTree(newSource, el => {

            // Generate new id
            const newId = nanoid(10);

            // Duplicate storage for Fragment
            if(el.type === 'Fragment') nodesToCopyStorage.push({fromId: el.id, toId: newId})

            // Add to links map
            linksMap[el.id] = newId
            el.id = newId

            // Add widget id
            if (el?.type && el.type.startsWith('Widget:')) {
                const [,widgetId] = el.type.split(':');

                widgetsId.add(widgetId);

                if (!widgetTitles.has(widgetId)) {
                    widgetTitles.set(widgetId, new Set);
                }

                if (el?.title) {
                    widgetTitles.get(widgetId).add(el.title);
                }
            }
        });

        // Copy storages
        for(const {fromId, toId} of nodesToCopyStorage) {
            await StorageNode.duplicate(component.module_id, app_id, module_id, fromId, toId)
        }

        // Patch links
        for(const link of newSource.children?.filter(l => l.type === 'link')) {
            link.properties.connection.source.id = linksMap[link.properties.connection.source.id]
            link.properties.connection.target.id = linksMap[link.properties.connection.target.id]
            link.id = Diagram.generateLinkId(link)
        }

        const oldTitle = component.title || '';

        const isTemplateDiagram = oldTitle.includes('[template]') || fromTemplate;

        const diagramTitle = newTitle || (isTemplateDiagram ? oldTitle.replace('[template]', '') : `${oldTitle} copy`);

        // create new component
        const newDiagram = await Diagram.save({
            title: diagramTitle,
            diagram_type: component.diagram_type,
            alias: component?.alias || null,
            description: component?.description || null,
            app_id: app_id,
            module_id: module_id,
            unique_id: parseInt(component.module_id) !== parseInt(module_id) ? component.unique_id : nanoid(10),
            status: "active",
            folder_id,
        });

        // Assign the ID of the newly created Diagram to the newSource object
        newSource.id = newDiagram.id;

        /**
         * Patch localization aliases
         * @param tree
         */
        async function patchLocalizationAliases(tree) {
            // Check if the block is marked as localizable and if it has a localeAlias
            if (tree?.isLocalizable && tree?.localeAlias) {
                // Find the localization record
                const localization = await Localization.query().where({module_id: module_id, alias: tree.localeAlias}).first();

                // If the localization record is found, duplicate it
                if (localization) {
                    // Duplicate the localization record
                    const newLocalization = await Localization.save({
                        ...localization,
                        id: null,
                        alias: nanoid(10),
                    });

                    // Duplicate the localization messages
                    const messages = await LocalizationMessage.query().where({localization_id: localization.id}).get();

                    for(const message of messages) {
                        await LocalizationMessage.save({
                            ...message,
                            localization_id: newLocalization.id,
                            id: undefined,
                        });
                    }

                    // Update the localeAlias
                    tree.localeAlias = newLocalization.alias;
                }
            }

            // Go deeper
            for (const prop of Object.values(tree || {})) {
                if (typeof prop === 'object') {
                    await patchLocalizationAliases(prop);
                }
            }
        }

        // Patch source
        await patchLocalizationAliases(newSource);

        const needPatchIds = new Map;

        // Find or duplicate widgets
        for (const widgetId of [...widgetsId.values()]) {
            const diagram = await Diagram.find(widgetId);

            if (!diagram?.id) {
                throw `Error while finding widget diagram: ${widgetId} (${Array.from(widgetTitles.get(widgetId)).join(', ')}). It may have been deleted`;
            }

            // Skip if the widget is already in the module
            if (diagram?.module_id === newDiagram.module_id) {
                continue;
            }

            // Find or duplicate widget
            let moduleWidget = (await Diagram.query().where({
                module_id: newDiagram.module_id,
                diagram_type: 'widget',
                unique_id: diagram.unique_id,
            }).get())?.[0];

            // Duplicate widget
            if (!moduleWidget) {
                moduleWidget = await Diagram.duplicate(widgetId, newDiagram.app_id, newDiagram.module_id, isTemplateDiagram);

                if (!moduleWidget) {
                    throw 'Error while duplicating widget';
                }
            }

            needPatchIds.set(widgetId, moduleWidget.id);
        }

        // Patch widget ids
        treeHelper.traverseTree(newSource, (node) => {
            if (node?.type && node.type.startsWith('Widget:')) {
                const [,widgetId] = node.type.split(':');

                if (needPatchIds.has(widgetId)) {
                    node.type = `Widget:${needPatchIds.get(widgetId)}`;
                }
            }
        });

        // Save the updated source object to the newly created Diagram.
        await Diagram.save({
            id: newDiagram.id,
            source: newSource
        });

        // Copy diagram storage
        await StorageNode.duplicate(component.module_id, app_id, module_id, `diagram-${diagram_id}`, `diagram-${newDiagram.id}`);

        // Copy app storage nodes
        if (component.module_id !== newDiagram.module_id) {
            const appStorageNodes = new Set();

            treeHelper.goDeeper(newSource, (node) => {
                if (node?.blockId === 'app-storage' && node?.valueType === 'variable' && node?.nodeId) {
                    appStorageNodes.add(node.nodeId);
                }
            });

            if (appStorageNodes.size) {
                for (const nodeId of appStorageNodes) {
                    await StorageNode.copyItemWithParents(nodeId, app_id, module_id);
                }
            }
        }

        // Saved
        return newDiagram;
    }

    /**
     * Generate link id
     * @param link
     */
    static generateLinkId(link) {

        // Construct id
        const id = link.properties.connection.source.id + '-' + link.properties.connection.source.event + '-' + link.properties.connection.source.unique + '->' +
        link.properties.connection.target.id + '-' + link.properties.connection.target.event + '-' + link.properties.connection.target.unique;

        // Create hash
        return 'l'+dataHelper.stringHash(id);
    }
}
