<template>

  <q-toolbar class="bg-primary text-white text-subtitle1">
    <q-btn label="Back to apps list" icon="arrow_back" flat @click="backToList"/>
  </q-toolbar>

  <div class="q-pa-md" v-if="currentModule?.id">
    <q-tree ref="tree" @update:selected="onNodeSelected"
            :nodes="appsTree"
            v-model:expanded="expandedNodes"
            v-model:selected="selectedNode"
            node-key="key"
            no-selection-unset
    />

  </div>

</template>

<style>
</style>

<script>
import {App} from "@/../../common/db/App";
import {AppModule} from "../../../../../common/db/AppModule";

export default {
  name: 'WorkspaceNavigation',
  inject: {
    currentModuleId: {},
    //currentAppVersion: {}
  },
  components: {},
  data: () => ({
    editApp: false,
    expandedNodes: ['apps-list'],
    selectedNode: false
  }),

  computed: {

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.currentModuleId), {})
    },

    healthCheckEnabled() {
      if (this.currentModule?.type !== 'server') {
        return false;
      }

      const {healthCheckStage, healthCheckRelease, stage, release} = this.currentModule?.endpoint;

      return (healthCheckStage === 1 && stage) || (healthCheckRelease === 1 && release)
    },

    /**
     * Construct navigation tree
     */
    appsTree() {

      // Construct navigation tree
      return this.wait("tree", async () => {

        // Prepare tree leafs
        const treeItems = [];

        // Get current app
        const p = await App.find(this.$route.params.app_id);

        // Ids segment
        const idsSegment = p.id + "/" + this.currentModuleId;

        // Integrations list
        const settings = [
          {
            label: "Module info",
            key: idsSegment + "/info",
          },
          {
            label: "General settings",
            key: idsSegment,
          },
          {
            label: "Operation systems",
            key: "_os",
            types: ["mobile", 'web'],
            children: [
              {
                label: "Android",
                types: ["mobile"],
                key: idsSegment + "/settings/os/android",
              },
              {
                label: "iOS",
                types: ["mobile"],
                key: idsSegment + "/settings/os/ios",
              },
              {
                label: "iOS Permissions",
                types: ["mobile"],
                key: idsSegment + "/settings/os/ios-permissions",
              },
              {
                label: "Web",
                types: ["web"],
                key: idsSegment + "/settings/os/web",
              },
            ],
          },
          {
            label: "Layout",
            types: ["mobile"],
            key: idsSegment + "/settings/layout",
          },
          {
            label: "Loader",
            types: ["mobile", 'web'],
            key: idsSegment + "/settings/loader",
          },
          {
            label: "Ads",
            types: ["mobile"],
            key: idsSegment + "/settings/admob",
          },
          {
            label: "In app purchases",
            types: ["mobile", 'web'],
            key: idsSegment + "/settings/iap",
          },
          {
            label: "Firebase",
            types: ["mobile", "web", "server"],
            key: idsSegment + "/settings/firebase",
          },
          {
            label: "Singular",
            types: ["mobile"],
            key: idsSegment + "/settings/singular",
          },
          {
            label: "Git",
            key: idsSegment + "/settings/git",
          },
          {
            label: "Big Query",
            types: ["mobile", "web"],
            key: idsSegment + "/settings/bq",
          },
          {
            label: "Facebook SDK",
            types: ["mobile"],
            key: idsSegment + "/settings/facebook",
          },
          /*{
            label: "Telegram bot",
            types: ["chat-bot"],
            key: idsSegment + "/settings/telegram-bot",
          },*/
          {
            label: "Localizations",
            key: idsSegment + "/settings/localizations",
          },
          {
            label: "Shopify",
            types: ['mobile', 'web'],
            key: idsSegment + "/settings/shopify",
          },
          {
            label: "Adapty",
            types: ['mobile'],
            key: idsSegment + "/settings/adapty",
          },
          {
            label: "Auth",
            key: idsSegment + "/settings/auth",
          },
          {
            label: "SendGrid",
            types: ['server'],
            key: idsSegment + "/settings/sendgrid",
          },
          {
            label: "Stripe",
            types: ['server'],
            key: idsSegment + "/settings/stripe",
          },
          {
            label: "Stripe",
            types: ['web'],
            key: idsSegment + "/settings/stripe-web",
          },
          {
            label: "In app purchases sync",
            types: ['server'],
            key: idsSegment + "/settings/iap-sync",
          },
          {
            label: "Marketing pixels",
            types: ['web'],
            key: idsSegment + "/settings/marketing-pixels",
          },
        ].map((item) => {
          if (!item.children) {
            return item;
          }

          const childItems = item.children.filter(i => !i.types || i.types?.includes(this.currentModule?.type));

          if (!childItems.length) {
            return null;
          }

          return {
            ...item,
            children: childItems,
          }
        }).filter((i) => !!i).filter(i => !i.types || i.types?.includes(this.currentModule?.type))

        // Fill menu
        treeItems.push(...[
          /*{
            label: "Navigation",
            key: p.id + "/diagram/navigation",
          },
          {
            label: "Backend",
            key: p.id + "/diagram/backend",
          },*/

          {
            label: "Diagrams",
            key: idsSegment + "/components/list",
          },
          /*{
              label: "Functions",
              key: idsSegment + "/functions/list",
          },*/
          {
            label: "Functions",
            key: idsSegment + "/functions",
          },
          {
            label: "Storage",
            key: idsSegment + "/storage",
          },
          {
            label: "Constants",
            key: idsSegment + "/constants",
          },
          ...(this.currentModule.type === 'server' ? [] : [{
            label: "Tree storage",
            key: idsSegment + "/tree-storage",
          }]),
          {
            label: "Styles",
            key: idsSegment + "/styles",
          },
          {
            label: "Releases",
            key: idsSegment + "/releases",
          },
          {
            label: "DB",
            key: idsSegment + "/dbs",
          },
          {
            label: "Media gallery",
            key: idsSegment + "/media-gallery",
          },
          ...(this.healthCheckEnabled ? [{
            label: "Health check",
            key: idsSegment + "/health-check",
          }] : []),
          {
            label: "Analytics",
            key: "_analytics",
            children: [
              {
                label: "Retention",
                key: idsSegment + "/analytics-retention",
              },
              {
                label: "KPI",
                key: idsSegment + "/analytics-kpi",
              },
              /*{
                label: "Funnels",
                key: idsSegment + "/analytics-funnels",
              },*/
              {
                label: "Paths",
                key: idsSegment + "/analytics-paths",
              },
              {
                label: "Audience",
                key: idsSegment + "/analytics-audience",
              },
            ]
          },
          /*{
            label: "Marketing",
            key: "_marketing",
            children: [
              {
                label: "Keywords",
                key: idsSegment + "/marketing/keywords",
              },
            ]
          },*/
          {
            label: "Module settings",
            children: settings
          },
        ]);

        // Return list
        return treeItems
      }, [])
    },
  },

  methods: {

    /**
     * Back to apps list
     */
    backToList() {
      this.$router.replace({name: "app-modules"})
    },

    /**
     * On node selected
     * @param nodeId
     */
    onNodeSelected(nodeId) {
      if (nodeId && nodeId.indexOf("_") !== 0) this.$router.push("/workspace/" + nodeId)
    },
  }
}
</script>
