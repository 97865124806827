<template>
  <div class="folders">
    <modal-dialog ref="editFolder" :title="`${editFolder?.id ? 'Edit' : 'New'} folder`" persistent>
      <folder-form
        :edit-folder="editFolder"
        @closed="$refs.editFolder.hide()"
      />
    </modal-dialog>

    <q-breadcrumbs class="q-px-md">
      <q-breadcrumbs-el icon="folder" label="Root" :to="{params: {folder_id: 0}}">
        <q-btn
          v-if="!breadcrumbs.length"
          class="q-mx-sm"
          icon="create_new_folder"
          size="sm"
          flat
          round
          color="positive"
          @click="editFolder={app_id,module_id,type,parent_id:0};$refs.editFolder.show()"
        />
      </q-breadcrumbs-el>

      <q-breadcrumbs-el
        v-for="folder in breadcrumbs"
        :key="folder.id"
        icon="folder"
        :to="folder.id !== parentId ? {params: {folder_id: folder.id}} : undefined"
        :label="folder.name"
      >
        <template v-if="folder.id === parentId">
          <q-btn
            class="q-mx-sm"
            icon="edit"
            size="sm"
            flat
            round
            color="primary"
            @click="editFolder=folder.data();$refs.editFolder.show()"
          />

          <q-btn
            icon="create_new_folder"
            size="sm"
            flat
            round
            color="positive"
            @click="editFolder={app_id,module_id,type,parent_id:parentId};$refs.editFolder.show()"
          />
        </template>
      </q-breadcrumbs-el>
    </q-breadcrumbs>

    <q-separator v-if="folders.length" spaced/>

    <div v-if="folders.length" class="folders-grid">
      <div
        v-for="folder in folders"
        :key="folder.id"
        class="folder-card cursor-pointer text-center"
        @click="$router.push({ params: { folder_id: folder.id } })"
      >
        <q-icon name="folder" size="64px" class="folder-card__icon text-primary" />
        <div class="text-caption text-bold">{{ folder.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Folder} from '../../../../../common/db/Folder';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import FolderForm from '@/pages/workspace/folders/FolderForm.vue';

export default {
  name: 'FoldersList',
  components: {FolderForm, ModalDialog},

  props: {
    type: {
      required: true,
      type: String,
    },
    app_id: {
      required: false,
      type: Number,
      default: 0,
    },
    module_id: {
      required: false,
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      editFolder: false,
    };
  },

  computed: {
    /**
     * Computes the parent folder ID from the current route parameters.
     * If no folder ID is present in the route, defaults to 0.
     *
     * @returns {number} The parent folder ID.
     */
    parentId() {
      return Number(this.$route.params.folder_id || 0);
    },

    /**
     * Fetches the list of folders based on the current type, app_id, module_id, and parentId.
     * Uses the `wait` method to handle asynchronous data fetching.
     *
     * @returns {Array} The list of folders.
     */
    folders() {
      return this.wait('folders', Folder.query().where({
        type: this.type,
        app_id: this.app_id,
        module_id: this.module_id,
        parent_id: this.parentId,
      }).get(), []);
    },

    /**
     * Computes the breadcrumb trail for the current folder.
     * If the parent folder ID is 0, returns an empty array.
     * Otherwise, fetches the parent folders recursively until the root is reached.
     *
     * @returns {Array} The breadcrumb trail as an array of folders.
     */
    breadcrumbs() {
      return this.wait('breadcrumbs', async () => {
        if (this.parentId === 0) {
          this.breadcrumbs = [];

          return [];
        }

        const folders = [];

        let parentId = this.parentId;

        while (parentId) {
          const folder = await Folder.find(parentId);

          if (!folder) {
            break;
          }

          folders.push(folder);
          parentId = folder.parent_id;
        }

        return folders.reverse();
      }, []);
    },
  },
}
</script>

<style scoped lang="scss">
.folders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  gap: 16px;
  padding: 16px;
}
.folder-card {
  &__icon {
    transition: transform 0.2s;
  }

  &:hover &__icon {
    transform: scale(1.05);
  }
}
</style>
