import {DbModule} from '../../../../../../../../common/db/DbModule';
import {StorageNode} from '../../../../../../../../common/db/StorageNode';
import {DbTable} from '../../../../../../../../common/db/DbTable';

export const dbListMixin = {
  computed: {
    /**
     * Computed property to get the list of databases.
     * Filters databases based on the module_id and type.
     *
     * @returns {Array} List of databases with value and label.
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
          .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
          .map(d => ({
            value: d.id,
            label: `${d.name} (${this.currentModule.modules[d.module_id]?.name})`
          }));
      }, []);
    },

    /**
     * Computed property to get the list of tree storages.
     * Filters storage nodes based on the app_id, module_id, block_id, and parent_id.
     *
     * @returns {Array} List of tree storages with value and label.
     */
    treeStorages() {
      return this.wait("treeStorages", async () => {
        return (await StorageNode.query().where({
          app_id: this.product_id,
          module_id: this.module_id,
          block_id: 'tree-storage',
          parent_id: 0,
        }).get()).map(d => ({
          value: `tree-storage:${d.id}`,
          label: `${d.name} (Tree storage)`
        }));
      }, []);
    },

    /**
     * Computed property to get the combined list of database options.
     * Merges the list of databases and tree storages.
     *
     * @returns {Array} List of database options with value and label.
     */
    dbOptions() {
      return [
        ...this.dbsList,
        ...this.treeStorages,
      ];
    },

    /**
     * Computed property to get the list of tables.
     * Filters tables based on the db_id from the block properties.
     *
     * @returns {Array} List of tables with value and label.
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },

    /**
     * Computed property to check if the selected database is a tree storage.
     *
     * @returns {boolean} True if the selected database is a tree storage, false otherwise.
     */
    isTreeStorageSelected() {
      return String(this.block?.properties?.dbId || '').startsWith('tree-storage:');
    },
  },
}
