<template>

  <ab-flow-base-cmp class="tableview-cmp" :block="block" :class="classesString" :style="stylesString">

    <q-table
        :columns="columns"
        :rows="items"
        :grid="isGrid"
        v-model:selected="selected"
        selection="single"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th/>
          <q-th
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            {{ col.label }}
            <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="slot" :unique="col.field"/>
          </q-th>
        </q-tr>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td/>
          <q-td
              v-for="col in props.cols"
              :key="col.name"
              :props="props"
          >
            <template v-if="cellSlots[col.name]">
              <data-provider :storage-key="block?.title" :data="{item:props.row}">
                <ab-flow-components-renderer :preview-mode="true" :items="cellSlots[col.name].children"/>
              </data-provider>
            </template>
            <template v-else>
              <template v-if="col.type === 'file'">
                file: {{ col.value?.source_url }}
              </template>
              <template v-else>
                {{ col.value }}
              </template>
            </template>
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <div class="events">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="select"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="row-click"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>
import get from 'lodash/get.js';
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "../../../renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {StorageNode} from "../../../../../../../../../common/db/StorageNode";
import {DbTable} from "../../../../../../../../../common/db/DbTable";
import {DbTableField} from "../../../../../../../../../common/db/DbTableField";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  inject: ['canvas'],
  mixins: [renderMixins],
  props: ['block'],
  name: "TableViewEditorCmp",
  data: () => ({
    selected: []
  }),
  computed: {

    /**
     * Return is grid
     * @return {*}
     */
    isGrid() {
      return this.block?.properties?.viewType === "grid"
    },

    /**
     * Get cell slots
     */
    cellSlots() {

      // Get slides list
      const slides = Object.fromEntries(this.canvas.links.filter(l => l.properties?.connection?.source?.id === this.block.id)?.map(l => [l.properties?.connection?.source?.unique, l.properties?.connection?.target?.id]))
      const frs = {};

      // Get slides
      for(const [key, sl] of Object.entries(slides)) {
        const fr = this.canvas.blocks.find(bl => bl.id === sl)
        if(fr) frs[key] = fr
      }

      // Return frames
      return frs
    },

    /**
     * Return fields list
     * @return {*|*[]}
     */
    columns() {
      return (this.block?.properties?.fields?.items || []).filter(i => i.name).map(i => ({
        name: i.name,
        label: i.title,
        field: i.name,
        type: i.type,
      }))
    },

    /**
     * Get mock items
     * @return {*}
     */
    items() {
      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if (node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table);
          const fields = await DbTableField.query().where("table_id", node.db_table).get();

          const defaultValues = (fields || []).reduce((acc, field) => {
            if (field?.default_value !== null && field?.default_value !== undefined) {
              acc[field.name] = field.default_value;
            }

            return acc;
          }, {});

          const testData = table?.table_data || table?.test_table_data || [];

          // check if table has data
          if(testData?.length) {
            return testData.slice(0, 20).map((item) => ({
              ...defaultValues,
              ...(Object.fromEntries(
                Object.entries(item).filter(([,value]) => value !== null && value !== undefined)
              )),
            }));
          } else if (node?.value && node?.is_test_value) {
            return this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
          }

          // Load table fields
          for (const fld of await DbTableField.query().where("table_id", node.db_table).get()) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else if (node?.type === 'tree-storage' && node?.tree_storage_node) {
          const treeStorageNodes = await StorageNode.query().where({
            app_id: node.app_id,
            module_id: node.module_id,
            block_id: 'tree-storage',
          }).get();

          const path = [];

          let parentId = node.tree_storage_node;

          while (parentId) {
            const parentNode = treeStorageNodes.find(n => n.id === parentId);
            path.unshift(`${parentNode.name}${parentNode.is_array && parentId !== node.tree_storage_node ? '.0' : ''}`);
            parentId = parentNode?.parent_id;
          }

          return get(this.designer.treeStorage, path.join('.'));
        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Return list
        return items
      }, [])
    }
  },
}
</script>

<style lang="scss">

.tableview-cmp {
  flex-direction: column;
  position: relative;

  .events {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 1;
  }
}

</style>
