<template>

  <ab-flow-base-cmp :draggable="false" class="content-swiper-stack-cmp" :block="block" :is_container="true" :class="classesString" :style="stylesString">

    <TransitionGroup name="card">
      <div v-for="(item, idx) in items" :key="idx" class="content-swiper-stack-cmp__card">
        <data-provider :storage-key="block?.title" :data="{item:item}">
          <ab-flow-components-renderer :items="block.children"/>
        </data-provider>
      </div>
    </TransitionGroup>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="selected"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>
import get from 'lodash/get.js';
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {DbTable} from "../../../../../../../../../common/db/DbTable";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "ContentSwiperStackEditorCmp",

  computed: {
    items() {
      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if(node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table);
          const fields = await DbTableField.query().where("table_id", node.db_table).get();

          const defaultValues = (fields || []).reduce((acc, field) => {
            if (field?.default_value !== null && field?.default_value !== undefined) {
              acc[field.name] = field.default_value;
            }

            return acc;
          }, {});

          const testData = table?.table_data || table?.test_table_data || [];

          // check if table has data
          if(testData?.length) {
            return testData.slice(0, 3).map((item) => ({
              ...defaultValues,
              ...(Object.fromEntries(
                Object.entries(item).filter(([,value]) => value !== null && value !== undefined)
              )),
            }));
          } else if (node?.value && node?.is_test_value) {
            return (this.getValue(this.block?.properties?.dataSource) || node?.value || [{}]).slice(0, 3);
          }

          // Load table fields
          for(const fld of await DbTableField.query().where("table_id", node.db_table).get()) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else if (node?.type === 'tree-storage' && node?.tree_storage_node) {
          const treeStorageNodes = await StorageNode.query().where({
            app_id: node.app_id,
            module_id: node.module_id,
            block_id: 'tree-storage',
          }).get();

          const path = [];

          let parentId = node.tree_storage_node;

          while (parentId) {
            const parentNode = treeStorageNodes.find(n => n.id === parentId);
            path.unshift(`${parentNode.name}${parentNode.is_array && parentId !== node.tree_storage_node ? '.0' : ''}`);
            parentId = parentNode?.parent_id;
          }

          return get(this.designer.treeStorage, path.join('.'));
        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Convert item elements to object
        items = (Array.isArray(items) ? items : []).map(el => {
          return typeof el === 'object' ? el : {value: el}
        })

        // Return list
        return items.length ? items.slice(0, 3) : [{}];
      }, [])
    }
  },
}

</script>


<style lang="scss">

.content-swiper-stack-cmp {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;

  &__card {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 60px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:first-child {
      transform: scale(1) translateY(0px);
      opacity: 1;
      z-index: 2;
    }

    &:nth-child(2) {
      transform: scale(0.95) translateY(-30px);
      opacity: 0.7;
      z-index: 1;
    }

    &:nth-child(3) {
      transform: scale(0.9) translateY(-60px);
      opacity: 0.4;
      z-index: 0;
    }

    &:not(:first-child) {
      transform-origin: top center;
    }
  }

  .results {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
}

</style>
