<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="code-function-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="q-pa-sm bg-green-2">
      <q-icon name="draw" size="md"/>
      {{title}}
    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="result"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "AIFunctionEditorCmp",
  computed: {

    /**
     * Return desc
     * @return {{}}
     */
    title() {
      return this.block.properties ? this.block.properties.title : "No title"
    },
  }
}

</script>

<style lang="scss">

.code-function-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
