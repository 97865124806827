<template>
  <q-dialog ref="editField">
    <table-field-form :edit-field="editField" @saved="$refs.editField.hide()"/>
  </q-dialog>

  <div class="">

    <template v-for="(field, k) of fields" :key="k">

      <div class="row items-center col">
        <strong>{{field.name}}</strong> ({{field.type}}) {{ getDefaultValue(field) }}
        <q-space/>
          <q-btn flat @click="editField = field.data();$refs.editField.show()" icon="edit" size="sm" round/>
          <q-btn flat @click="deleteField(field)" icon="delete" size="sm" round/>
      </div>
      <div
        v-if="field.description"
        v-text="field.description"
        class="text-caption"
      />

    </template>

  </div>

  <q-separator/>

  <div class="column items-end q-pt-sm">
    <q-btn icon="add" flat label="Add field" size="sm" @click="editField={table_id: tableId};$refs.editField.show()"/>
  </div>

</template>

<style>
</style>

<script>
import TableFieldForm from "./TableFieldForm";
import {DbTableField} from "@/../../common/db/DbTableField";

export default {
  name: 'TableFieldsList',
  components: {TableFieldForm},
  props: {
    tableId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    editField: false,
    module_id: false
  }),

  async created() {
    // Subscribe to fields
    await DbTableField.remote().subscribe("table-fields",{table_id: this.tableId});
  },

  computed: {
    // Return all fields from the store
    fields() {
      return this.wait("table-fields", DbTableField.query().where({table_id: this.tableId}).get(), [])
    }
  },

  methods: {

    /**
     * Delete field
     * @param field
     * @return {Promise<void>}
     */
    async deleteField(field) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${field.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        DbTableField.remote().delete(field.id)
      })
    },

    /**
     * Get the default value of a field.
     * @param {Object} field - The field object.
     * @param {string} field.type - The type of the field.
     * @param {*} field.default_value - The default value of the field.
     * @returns {string} The formatted default value string.
     */
    getDefaultValue(field) {
      if (field?.default_value === null || field?.default_value === undefined) {
        return '';
      }

      let defValue;

      switch (field.type) {
        case 'bool':
          defValue = field.default_value === 1 ? 'True' : 'False';
          break;
        default:
          defValue = field.default_value;
      }

      return `(Default: ${defValue})`;
    },
  }
}
</script>
