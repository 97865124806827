<template>

  <div class="row q-pl-sm">
    <q-dialog ref="backupsList" class="full-width full-height">
      <q-card class="full-width full-height column">
        <q-card-section class="bg-primary text-white row">
          <div class="text-subtitle1">Diagram history</div>
          <q-space/>
          <q-btn icon="close" size="sm" round flat @click="$refs.backupsList.hide()"/>
        </q-card-section>
        <q-card-section class="col">
          <diagram-backup-list :diagram-id="app.currentMediaDesigner.diagram_id" @restore="restoreVersion($event); $refs.backupsList.hide();"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog ref="uiTemplatesList" class="full-width full-height">
      <q-card class="full-width full-height column">
        <q-card-section class="bg-primary text-white row">
          <div class="text-subtitle1">UI Templates</div>
          <q-space/>
          <q-btn icon="close" size="sm" round flat @click="$refs.uiTemplatesList.hide()"/>
        </q-card-section>
        <q-card-section class="col">
          <ui-templates-fragments-list
            @paste-ui-template="$emit('paste-ui-template', $event);
            $refs.uiTemplatesList.hide();"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <modal-dialog ref="validationErrors" title="Problems" persistent max-width="800px">
      <q-list class="q-mt-lg">
        <template
          v-for="(item, idx) in validationErrorsItems"
          :key="idx"
        >
          <q-separator v-if="idx > 0" class="q-my-md" />
          <q-item class="shadow-1">
            <q-item-section>
              <q-item-label>
                Component: <a href="javascript:;" @click="selectBlock(item.blockId)">{{ item.title }}</a>
              </q-item-label>
              <q-item-label class="text-negative">{{ item.message }}</q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </modal-dialog>

    <q-select label="Styles theme" v-model="app.currentMediaDesigner.currentStylesTheme" :options="themesOptions" map-options emit-value/>

    <q-select
      v-if="languages.length"
      label="Locale"
      v-model="app.currentMediaDesigner.currentLocale"
      :options="languages"
      map-options
      emit-value
    />
    <q-space/>

    <q-btn
      v-if="validationErrors && validationErrors.length"
      flat
      text-color="negative"
      label="Problems"
      @click="$refs.validationErrors.show()"
    ><q-badge color="negative" floating class="q-mt-sm" :label="validationErrors.length" /></q-btn>

    <q-btn v-if="!isExternalEditor" flat @click="$refs.uiTemplatesList.show()">Add template</q-btn>

    <q-btn v-if="hasUndo" icon="undo" flat @click="undoDesign"/>
    <q-btn v-if="hasRedo" icon="redo" flat @click="redoDesign"/>
    <q-btn icon="history" flat @click="$refs.backupsList.show()"/>
    <q-separator/>

    <q-btn v-if="!isExternalEditor" split label="Run: designer" flat icon="play_arrow" @click="runApplication"/>

    <!--q-btn-dropdown split :label="`Run: ${runMode}`" flat icon="play_arrow" @click="runApplication">
      <q-list>
        <q-item clickable v-close-popup @click="runMode='debug'">
          <q-item-section>
            <q-item-label>Debug</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="buildVersion('stage')">
          <q-item-section>
            <q-item-label>Stage</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable v-close-popup @click="buildVersion('release')">
          <q-item-section>
            <q-item-label>Release</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown!-->

    <q-btn v-if="hasChanges" label="Save" icon="save" flat @click="saveCurrentDesign"/>
  </div>
</template>

<script>
import { debounce } from 'quasar';
import DiagramBackupList from "@/components/DiagramDesigner/Editor/DiagramBackupList.vue";
import UiTemplatesFragmentsList from '@/components/DiagramDesigner/Editor/UiTemplatesFragmentsList.vue';
import {SchemaVarsValidator} from '../../../../../common/utils/SchemaVarsValidator';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';

export default {
  name: "MediaDesignerToolbar",
  components: {ModalDialog, DiagramBackupList, UiTemplatesFragmentsList},
  emits: ['paste-ui-template'],
  inject: ['designer'],

  data: () => ({
    runMode: "debug",
    validationErrors: false,
  }),
  methods: {

    /**
     * Restore version
     * @param source
     */
    restoreVersion(source) {
      // Set new source
      this.app.currentMediaDesigner.setSource(source)
    },

    /**
     * Build version
     * @param mode
     */
    buildVersion(mode) {
      this.app.currentMediaDesigner.buildApp(mode)
    },

    /**
     * Run application
     */
    runApplication() {
      this.app.currentMediaDesigner.run(this.runMode)
    },

    /**
     * Undo design
     */
    undoDesign() {
      this.app.currentMediaDesigner.undo()
    },

    /**
     * Redo design
     */
    redoDesign() {
      this.app.currentMediaDesigner.redo()
    },

    /**
     * Save current design.
     */
    saveCurrentDesign() {
      this.app.currentMediaDesigner.save()
    },

    /**
     * Validate the current diagram using the SchemaVarsValidator.
     * This method sets the validationErrors property with the result of the validation.
     */
    async validateDiagram() {
      const validator = new SchemaVarsValidator(this.diagram);

      this.validationErrors = await validator.validate();
    },

    /**
     * Select a block by its ID.
     * This method hides the validation errors dialog, logs the block ID, centers the editor on the block, and selects the block.
     * @param {string} id - The ID of the block to select.
     */
    selectBlock(id) {
      this.$refs.validationErrors.hide();

      console.log(`Select block: ${id}`);

      this.app.currentMediaDesigner.$refs.editor.centerOnBlock(id);
      this.app.currentMediaDesigner.$refs.editor.selectObjectIds([id]);
    },
  },

  computed: {

    /**
     * Check for external source
     * @return {{type: Object | ObjectConstructor, default: null}}
     */
    isExternalEditor() {
      return this.designer?.externalSource
    },

    /**
     * Themes options
     * @return {(function(): *)|*}
     */
    themesOptions() {
      return this.app.currentMediaDesigner.styleTitles
    },

    /**
     * If app has changes
     * @returns {any}
     */
    hasChanges() {
      return this.app.currentMediaDesigner ? this.app.currentMediaDesigner.hasChanges : false
    },

    /**
     * Hash undo
     * @return {*|boolean}
     */
    hasUndo() {
      return this.app.currentMediaDesigner?.history.hasUndo
    },

    /**
     * Hash redo
     * @return {*|boolean}
     */
    hasRedo() {
      return this.app.currentMediaDesigner?.history.hasRedo
    },

    /**
     * Returns a list of languages that are available in the current media designer.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the global options.
     * - It filters the list of languages to only include those that are included in the `appLocales` of the current media designer.
     * - If `appLocales` is not defined, it defaults to an empty array.
     *
     * @returns {Array} An array of languages that are available in the current media designer.
     */
    languages() {
      return this.globals.options.languages.filter(
        (v) => (this.app.currentMediaDesigner?.appLocales || []).includes(v.value),
      );
    },

    /**
     * Get the current version of the media designer.
     * @returns {number} The current version or 0 if not available.
     */
    currentVersion() {
      return this.app?.currentMediaDesigner?.current_version || 0;
    },

    /**
     * Get the current diagram object.
     * @returns {Object} The current diagram or an empty object if not available.
     */
    diagram() {
      return this.app?.currentMediaDesigner?.diagram || {};
    },

    /**
     * Computed property that transforms validation errors into a list of items
     * with relevant information for display.
     *
     * @returns {Array} An array of objects containing message, title, and blockId.
     */
    validationErrorsItems() {
      return this.validationErrors.map((item) => {
        const {block} = item?.data || {};

        return {
          message: item.message,
          title: block?.properties?.title || block?.title || block?.alias,
          blockId: block?.id,
        };
      });
    },
  },

  watch: {
    currentVersion: debounce(function() { this.validateDiagram(); }, 1000),
  },

  mounted() {
    this.validateDiagram();
  },
}

</script>
