import {P3PNodeService} from "@/pages/p3p/utils/P3PNodeService";
import {computed} from "vue";

export const p3pMixins = {

    data: () => ({
        p3p: null,
        token: null,
    }),

    provide: function () {
        return {
            p3p: computed(() => this.p3p),
        }
    },

    /**
     * Created
     * @return {Promise<void>}
     */
    async created() {

        // Store token
        this.token = this.$route.params.token

        // Init p3p
        this.p3p = await new P3PNodeService(this.token).init()

        // Call onInited
        await this?.onInited?.();
    },

}
