<template>
  <div>
    <dynamic-string
      :multiple="true"
      v-model="dynamicString"
      :app-id="product_id"
      :module-id="module_id"
      :hint="hint"
      :block-id="block.id"
      :title="title || label"
      :parent-diagram-id="diagram_id"
      :ai-text-generator="false"
    >
      <template #actions>
        <ai-sql-generator v-model="sqlQuery" />

        <sql-executor
          v-if="sqlQuery"
          :query="sqlQuery"
          :bindings="dynamicString?.bindings || {}"
        />
      </template>
    </dynamic-string>
  </div>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import AiSqlGenerator from '@/components/AI/AiSqlGenerator.vue';
import SqlExecutor from '@/components/SqlEditor/SqlExecutor.vue';

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropSqlEditor",

  components: {SqlExecutor, DynamicString, AiSqlGenerator},

  props: {
    defaultValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
  },

  data() {
    return {
      dynamicString: {
        value: "",
        bindings: {}
      }
    };
  },

  computed: {
    sqlQuery: {
      get() {
        return typeof this.dynamicString === 'string' ? this.dynamicString : this.dynamicString.value;
      },
      set(value) {
        if (typeof this.dynamicString === 'string') {
          this.dynamicString = value;
        } else {
          this.dynamicString.value = value;
        }
      },
    },
  },

  created() {
    // Init current value
    if(typeof this.currentValue === 'object') this.dynamicString = this.currentValue;
    if(typeof this.currentValue === 'string') this.dynamicString.value = this.currentValue;

    // Add watcher
    this.$watch('dynamicString', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },
}

</script>
