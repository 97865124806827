<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Marketing pixels
    </div>
    <q-form @submit="saveProperties">

      <q-input label="Facebook pixel ID" v-model="properties.facebookId"/>
      <q-input label="TikTok pixel ID" v-model="properties.tiktokId"/>

      <q-separator/>

      <div class="q-ma-sm">

        <div class="q-mb-md row text-h6 items-center">
          Postback list:
          <q-space/>
          <q-btn label="Add postback" color="primary" class="q-mt-md" @click="addPostback" icon="add"/>
        </div>

        <q-banner v-if="!postbacks.length" class="bg-grey-3 q-ma-sm">
          No postback
        </q-banner>

        <q-card v-for="(postback, idx) of postbacks" :key="idx">
          <q-card-section class="row q-gutter-sm">
            <q-input class="col" label="Name" v-model="postback.name"/>
            <q-input class="col" label="Url" v-model="postback.url"/>
            <q-select class="col" map-options emit-value label="Action type" v-model="postback.actionType" :options="actionTypes"/>

            <q-btn label="Delete" flat @click="deletePostback(idx)"/>
          </q-card-section>
        </q-card>

      </div>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: 'MarketingPixelsIntegrationSettings',
  data: () => ({
    placement: {},
  }),

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: 'marketing-pixels', type: 'analytics'}),

    /**
     * Computed property to get the list of postbacks.
     * If `properties.postbacks` is not defined, it returns an empty array.
     *
     * @returns {Array} List of postbacks
     */
    postbacks() {
      return this.properties?.postbacks || [];
    },

    /**
     * Computed property to get the list of action types for marketing pixels.
     * It maps the global options to a format suitable for the select component.
     *
     * @returns {Array} List of action types with label and value
     */
    actionTypes() {
      return this.globals.options.collections.marketingPixelEvents
        .map((o) => ({
          label: o.title,
          value: o.value,
        }))
    },
  },

  methods: {
    /**
     * Adds a new postback to the properties.postbacks array.
     * If the postbacks array does not exist, it initializes it.
     */
    addPostback() {
      if (!this.properties.postbacks) this.properties.postbacks = [];

      this.properties.postbacks.push({
        name: '',
        url: '',
        actionType: '',
      });
    },

    /**
     * Deletes a postback from the properties.postbacks array by index.
     * Prompts the user with a confirmation dialog before deletion.
     *
     * @param {number} idx - The index of the postback to delete.
     */
    deletePostback(idx) {
      this.$q.dialog({
        title: 'Delete postback',
        message: 'Are you sure you want to delete this postback?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        // Remove postback from an array by index
        this.properties.postbacks.splice(idx, 1)
      })
    },
  },
}

</script>
