<template>
  <div class="q-mb-md">
    <template v-if="field.is_array">
      <tree-storage-array-field
        :field="field"
        :value="value[field.name]"
        @update="val => updateValue(field.name, val)"
      />
    </template>

    <template v-else-if="field.type === 'object'">
      <tree-storage-object-field
        :field="field"
        :value="value[field.name]"
        @update="val => updateValue(field.name, val)"
      />
    </template>

    <template v-else>
      <tree-storage-simple-field
        :field="field"
        :value="value[field.name]"
        @update="val => updateValue(field.name, val)"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TreeStorageField',

  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    updateValue(key, val) {
      this.$emit('update', {
        ...this.value,
        [key]: val
      })
    }
  }
}
</script>
