<template>

  <modal-dialog ref="result" title="SQL query result" full-width full-height>
    <q-markup-table class="q-mt-md" dense>
      <thead v-if="tableItems.length">
      <tr>
        <th
          v-for="header in tableHeaders"
          :key="header"
          class="text-left"
          v-text="header"
        />
      </tr>
      </thead>
      <tbody>
      <tr v-if="!tableItems.length">
        <td colspan="10" class="text-center text-grey">
          No data
        </td>
      </tr>
      <tr v-for="(item, idx) in tableItems" :key="idx">
        <td v-for="value in item" :key="value" v-text="value" />
      </tr>
      </tbody>
    </q-markup-table>
  </modal-dialog>

  <modal-dialog ref="bindings" title="Edit bindings">
    <div class="row full-width" v-for="field in Object.keys(bindingsData)" :key="field">
      <q-select
        v-if="bindingsData[field].type === 'bool'"
        class="col"
        v-model.number="bindingsData[field].value"
        :options="booleanValues"
        map-options
        emit-value
      />
      <q-input
        v-else
        class="col"
        v-model="bindingsData[field].value"
        :label="field"
        lazy-rules
        :hint="bindingsData[field].type === 'array' ? 'Separate values with commas' : ''"
        :rules="[ val => val && val.length > 0 || 'Please type something']"
      />

      <q-select
        class="q-ml-md"
        v-model="bindingsData[field].type"
        :options="bindingTypes"
        map-options
        emit-value
      />
    </div>

    <div class="q-mt-md">
      <q-btn icon="play_arrow" label="Run" color="positive" @click="loadExecutor"/>
    </div>
  </modal-dialog>

  <iframe
    v-if="executorEnabled"
    ref="executor"
    class="hidden"
    :src="emulatorUrl"
    @load="onLoadExecutor"
    @error="onErrorLoadExecutor"
  />

  <q-btn
    icon="play_arrow"
    round
    size="sm"
    color="positive"
    flat
    @click="onExecuteClick"
    :loading="isInitializing && executorEnabled"
  />
</template>

<script>
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';

export default {
  name: 'SqlExecutor',
  components: {ModalDialog},

  props: {
    query: {
      required: true,
      type: String,
    },
    bindings: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      executorEnabled: false,
      isInitializing: false,
      initializationTimeout: null,
      isExecutorReady: false,
      queryResult: [],
      bindingsData: {},
      bindingTypes: [
        {value: 'string', label: 'String'},
        {value: 'array', label: 'Array'},
        {value: 'bool', label: 'Boolean'},
      ],
      booleanValues: [
        {value: 1, label: 'True'},
        {value: 0, label: 'False'},
      ],
    };
  },

  computed: {
    /**
     * Computes the module ID from the current route parameters.
     *
     * @returns {string} The module ID.
     */
    moduleId() {
      return this.$route.params.module_id;
    },
    /**
     * Computes the URL for the SQL executor emulator.
     *
     * @returns {string} The emulator URL.
     */
    emulatorUrl() {
      return `${process.env.VUE_APP_EMULATOR_URL}/${this.moduleId}/internal-debug-mode`;
    },
    /**
     * Computes the table headers from the query result.
     *
     * @returns {Array<string>} An array of table header names.
     */
    tableHeaders() {
      if (!this.queryResult.length) {
        return [];
      }

      return Object.keys(this.queryResult[0]);
    },
    /**
     * Computes the table items from the query result.
     *
     * @returns {Array<Array<string>>} An array of table items.
     */
    tableItems() {
      return this.queryResult.map((item) => Object.values(item));
    },

    /**
     * Computes the fields for the bindings.
     *
     * @returns {Array<string>} An array of binding field names.
     */
    bindingsFields() {
      return Object.keys(this.bindings);
    },

    /**
     * Checks if there are any bindings available.
     *
     * @returns {boolean} True if there are bindings, false otherwise.
     */
    hasBindings() {
      return this.bindingsFields.length > 0;
    },
  },

  methods: {

    /**
     * Handles the click event for executing the SQL query.
     * If there are bindings, it initializes the bindings data and shows the bindings modal.
     * Otherwise, it loads the SQL executor.
     */
    async onExecuteClick() {
      if (this.hasBindings) {
        this.bindingsData = Object.fromEntries(this.bindingsFields.map((field) => [
          field,
          this.bindingsData[field] || { value: '', type: 'string' }
        ]))

        this.$refs.bindings.show();
      } else {
        await this.loadExecutor();
      }
    },

    /**
     * Handles the click event for executing the SQL query.
     * Shows a loading indicator and initializes the executor if not already enabled.
     * If the executor is ready, it executes the SQL query.
     */
    async loadExecutor() {
      this.$q.loading.show({
        message: 'Executing SQL query...'
      });

      if (!this.executorEnabled) {
        this.isInitializing = true;
        this.executorEnabled = true;
      } else if (this.isExecutorReady) {
        this.executeSqlQuery();
      }
    },

    /**
     * Handles the load event for the SQL executor iframe.
     * Adds a message event listener, waits for the executor to be ready,
     * sets the executor state, and executes the SQL query.
     * If an error occurs during initialization, it hides the loading indicator
     * and shows a notification with the error message.
     */
    async onLoadExecutor() {
      try {
        window.addEventListener('message', this.handleExecutorMessage);

        await this.waitForExecutorReady();

        this.isExecutorReady = true;
        this.isInitializing = false;

        this.executeSqlQuery();
      } catch (error) {
        this.$q.loading.hide();
        this.$q.notify({
          color: 'negative',
          message: 'Failed to initialize SQL executor: ' + error.message,
        });
        this.isInitializing = false;
      }
    },

    /**
     * Executes the SQL query by sending a message to the SQL executor iframe.
     * The message contains the event type 'sql-execute' and the query data.
     */
    executeSqlQuery() {
      this.$refs.executor.contentWindow.postMessage({
        eventType: 'sql-execute',
        data: JSON.parse(JSON.stringify({
          query: this.query,
          bindings: Object.fromEntries(
            Object.entries(this.bindingsData).map(
              ([field, item]) => [field, item.type === 'array' ? item.value.split(',') : item.value]
            )
          ),
        })),
      }, '*');
    },

    /**
     * Waits for the SQL executor to be ready.
     * Adds a message event listener and sends a 'check-ready' message to the SQL executor iframe.
     * Resolves the promise when the executor is ready, or rejects if initialization times out.
     *
     * @returns {Promise<void>} A promise that resolves when the executor is ready.
     */
    async waitForExecutorReady() {
      return new Promise((resolve, reject) => {
        this.initializationTimeout = setTimeout(() => {
          reject(new Error('Initialization timeout'));
        }, 30000);

        const checkReady = (event) => {
          const {eventType} = event.data;

          if (eventType === 'executor-ready') {
            window.removeEventListener('message', checkReady);
            clearTimeout(this.initializationTimeout);
            resolve();
          }
        };

        window.addEventListener('message', checkReady);

        this.$refs.executor.contentWindow.postMessage({
          eventType: 'check-ready'
        }, '*');
      });
    },

    /**
     * Handles messages from the SQL executor iframe.
     * Processes the result or error of the SQL query execution.
     *
     * @param {MessageEvent} event - The message event from the SQL executor iframe.
     */
    handleExecutorMessage(event) {
      const {eventType, data, error} = event.data;

      switch (eventType) {
        case 'sql-executed:result':
          this.queryResult = data?.result || [];

          this.$refs.result.show();
          break;
        case 'sql-executed:error':
          this.$q.notify({
            color: 'negative',
            message: 'SQL execution error: ' + (error?.message || error || 'Unknown error'),
          });
          break;
        default:
          return;
      }

      this.$q.loading.hide();
    },

    /**
     * Handles the error event for loading the SQL executor iframe.
     * Hides the loading indicator, sets the initializing state to false,
     * and shows a notification with the error message.
     */
    onErrorLoadExecutor() {
      this.$q.loading.hide();
      this.isInitializing = false;

      this.$q.notify({
        color: 'negative',
        message: 'Error loading SQL executor',
      });
    },
  },

  beforeUnmount() {
    window.removeEventListener('message', this.handleExecutorMessage);
    clearTimeout(this.initializationTimeout);
  },
}
</script>
