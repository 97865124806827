<template>
  <p3-p-node-picker label="Diagram" v-model="currentValue"/>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import P3PNodePicker from "@/pages/p3p/components/P3PNodePicker.vue";

export default {
  components: {P3PNodePicker},
  mixins: [propertiesMixins],
  name: "UiPropP3pNodePicker",
  data: () => ({
  }),
}

</script>
