<template>

  <ui-prop-select name="position" title="Navigtion position" default-value="up" :block="block" :options="globals.options.expandableDirection"/>
  <ui-prop-select name="viewMode" title="View: Icons/Images" default-value="icon" :block="block" :options="viewModes"/>

  <ui-prop-checkbox v-if="!vertical" class="q-mt-sm" title="Disable justify alignment" name="disableJustifyAlignment" :block="block" :default-value="0"/>

  <ui-data-props name="items" :default-schema="defaultSchema" :block="block" :key="viewMode"/>

  <ui-prop-value-selector :block="block" name="dynamicItems" only-var-type="object" title="Dynamic items" :parent-diagram-id="diagram_id"/>

  <ui-prop-value-selector :block="block" name="field" only-var-type="string" title="Tab variable" :parent-diagram-id="diagram_id"/>
  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  mixins: [propertiesMixins],
  name: "NavigationPanelPropsCmp",
  props: ['block'],
  components: {UiPropCheckbox, UiPropSelect, UiPropValueSelector, UiDataProps, UiDynamicProps},
  data() {
    return {
      viewModes: [
        {value: "icon", label: "Icon"},
        {value: "image", label: "Image"},
      ],
    };
  },

  computed: {
    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * Default schema
     * @return {({name: string, type: string}|{name: string, type: string})[]}
     */
    defaultSchema() {
      return [
        {
          title: "Title",
          name: "title",
          type: "localizable"
        },
        ...(this.viewMode === 'icon' ? [
          // If icon mode
          {
            title: "Icon",
            name: "icon",
            type: "icon"
          },
        ] : [
          // If image mode
          {
            title: "Image",
            name: "image",
            type: "image"
          },
          {
            title: "Active image",
            name: "activeImage",
            type: "image"
          },
        ]),
        {
          title: "Value",
          name: "value",
          type: "string"
        },
        {
          title: "Visibility",
          name: "visibility",
          type: "variable"
        },
      ];
    },
  },
}

</script>

