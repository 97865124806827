export const defaultStyles = {
    "colors": {
        "primary": {"foreground": "#333", "background": "#ffffff"},
        "secondary": {"foreground": "#444", "background": "#6c757d"},
        "tertiary": {"foreground": "#555", "background": "#d0d0d0"},
        "info": {"foreground": "#17a2b8", "background": "#17a2b8"},
        "success": {"foreground": "#0a0", "background": "#28a745"},
        "danger": {"foreground": "#a00", "background": "#dc3545"},
        "warning": {"foreground": "#ffc107", "background": "#ffc107"},
        "dark": {"foreground": "#000", "background": "#343a40"},
        "light": {"foreground": "#fff", "background": "#f8f9fa"}
    },
    "textStyles": {
        "h1": {"fontSize": "3", "fontWeight": "700", "fontFamily": "Montserrat"},
        "h2": {"fontSize": "2.7", "fontWeight": "700", "fontFamily": "Montserrat"},
        "h3": {"fontSize": "2.3", "fontFamily": "Montserrat", "fontWeight": "400"},
        "h4": {"fontSize": "2", "fontFamily": "Montserrat", "fontWeight": "400"},
        "title": {"fontSize": "1.8", "fontFamily": "Montserrat", "fontWeight": "400"},
        "sub-title": {"fontSize": "1.8", "fontFamily": "Montserrat", "fontWeight": "400"},
        "regular": {"fontSize": "1.4", "fontFamily": "Montserrat", "fontWeight": "400"},
        "small": {"fontSize": "0.8", "fontFamily": "Montserrat", "fontWeight": "400"}
    }
}
