<template>

  <div>
    <ui-prop-value-selector :block="block" name="dataSource" title="Data array to process" :is-array="true" :parent-diagram-id="diagram_id"/>
  </div>

</template>

<script>

import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector},
  props: ['block'],
  name: "ForEachPropsCmp",
  computed: {
  }
}

</script>
