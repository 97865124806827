<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      iOS Permissions
    </div>
    <q-form @submit="saveProperties">

      <q-input
        v-for="permission in permissionsList"
        :key="permission.value"
        class="col q-my-md"
        :label="permission.value"
        :hint="`Default: ${permission.label}`"
        v-model="properties[permission.value]"
      />

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>
import {constants} from '../../../../../../../common/constants/constants.js';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

const staticPermissions = [
  'NSCameraUsageDescription:We use your camera to take photos.',
  'NSPhotoLibraryAddUsageDescription:We use your photo library to save photos.',
  'NSPhotoLibraryUsageDescription:We use your photo library to select photos.',
];

export default {
  mixins: [integrationsMixin],
  name: "IosPermissionsIntegrationSettings",
  data() {
    return {
      appId: false,
      moduleId: false,
    };
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "ios-permissions", type: "os"}),

    permissionsList() {
      return [
        ...constants.options.permissionsList.map((p) => p?.ios || false).flat().filter(Boolean),
        ...staticPermissions,
      ].map((v) => {
        const [value,label] = v.split(':');

        return {value, label};
      });
    },
  },

  created() {
    this.appId = this.$route.params.app_id || 0;
    this.moduleId = this.$route.params.module_id || 0;

  },
}

</script>
