<template>

  <div class="q-gutter-y-sm">
    <div>Web build list</div>

    <q-btn color="primary" @click="buildApp">Build</q-btn>
  </div>
</template>

<script>

export default {
  name: "WebBuilderBuilds",
  inject: ["p3p"],
  methods: {
    /**
     * Build application
     */
    buildApp() {
      this.app.client.call("p3p", "webBuild", this.p3p.token)
    }
  }
}
</script>
