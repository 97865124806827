/**
 * User purchase model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';
import {AppModule} from './AppModule.js';
import {nanoid} from 'nanoid';
import {LocalizationMessage} from './LocalizationMessage.js';

export class Localization extends AccessModel {
    static entity = 'localizations'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        alias: "string",
        type: "string",
        context: "string",
        max_length: "int",
    }

    /**
     * Handles server events by clearing the cache for the associated module.
     *
     * @async
     * @function serverEvent
     * @returns {Promise<void>}
     */
    async serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        const row = await Localization.find(this.id);

        if (!row?.id) {
            return;
        }

        const module = await AppModule.find(row.module_id);

        if (module?.app_id) {
            cacheManager.clearCache(module.app_id);
        }
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkLocalizationAccess(await this.getAccessId(row, 'module_id'), row?.id) ? row : false;
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            // Localizations for module
            'module-localizations': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => {
                    return this.query().where(Array.isArray(module_id) ? Localization.sql().in('module_id', module_id) : {module_id}).get()
                },
            },
            // All localizations
            'localizations': {
                subscribe: () => 1,
                init: async () => this.query().get(),
            }
        }
    }

    /**
     * Duplicates a localization from one module to another.
     *
     * @param {number} from_module_id - The ID of the module to duplicate from.
     * @param {number} to_module_id - The ID of the module to duplicate to.
     * @param {string} locale_alias - The alias of the locale to duplicate.
     * @returns {Promise<string|null>} The alias of the new locale, or null if the locale was not found.
     */
    static async duplicate(from_module_id, to_module_id, locale_alias) {
        const locale = await Localization.query().where({
            module_id: from_module_id,
            alias: locale_alias,
        }).first();

        if (!locale) {
            return null;
        }

        // Duplicate locale
        const newLocale = await Localization.save({
            ...locale,
            id: null,
            module_id: to_module_id,
            alias: nanoid(10),
        });

        // Duplicate messages
        const messages = await LocalizationMessage.query().where({
            localization_id: locale.id,
        }).get();

        for (const message of messages) {
            await LocalizationMessage.save({
                ...message,
                localization_id: newLocale.id,
                id: null,
            });
        }

        return newLocale.alias;
    }
}
