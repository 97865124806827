import {AccessModel} from './AccessModel.js';

/**
 * Diagram model
 */
export class Folder extends AccessModel {
    static entity = 'folders'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        parent_id: "int",
        type: "string",
        name: "string",
    }

    /**
     * Clears the cache for the current diagram's application.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Returns the channels for the Folder model.
     *
     * @returns {Object} An object containing the channels.
     */
    async channels() {
        return {
            'app-folders': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => Folder.query().where({app_id}).get(),
            },
            'module-folders': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => Folder.query().where({module_id}).get(),
            },
            'folder': {
                subscribe: ({id}) => id,
                init: async ({id}) => Folder.find(id),
            },
            'folders': {
                subscribe: ({app_id, module_id, type}) => `${app_id}-${module_id}-${type}`,
                init: async ({app_id = 0, module_id = 0, type}) => Folder.query().where({app_id, module_id, type}).get(),
            },
        }
    }
}
