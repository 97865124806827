import ValueSelectorOperation from "@/components/ValueSelector/ValueSelectorOperation.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiTemplatesDiagramsList from '@/components/DiagramDesigner/Editor/UiTemplatesDiagramsList.vue';

import TreeStorageContent from '@/pages/workspace/storage/tree-storage-editor/TreeStorageContent.vue';
import TreeStorageField from '@/pages/workspace/storage/tree-storage-editor/TreeStorageField.vue';
import TreeStorageSimpleField from '@/pages/workspace/storage/tree-storage-editor/TreeStorageSimpleField.vue';
import TreeStorageObjectField from '@/pages/workspace/storage/tree-storage-editor/TreeStorageObjectField.vue';
import TreeStorageArrayField from '@/pages/workspace/storage/tree-storage-editor/TreeStorageArrayField.vue';

export const registerComponents = (app) => {
    app.component("value-selector-operation", ValueSelectorOperation)
    app.component("value-selector", ValueSelector)
    app.component("ui-prop-arguments", UiPropArguments)
    app.component("ui-templates-diagrams-list", UiTemplatesDiagramsList);

    app.component("tree-storage-content", TreeStorageContent);
    app.component("tree-storage-field", TreeStorageField);
    app.component("tree-storage-simple-field", TreeStorageSimpleField);
    app.component("tree-storage-object-field", TreeStorageObjectField);
    app.component("tree-storage-array-field", TreeStorageArrayField);
}
