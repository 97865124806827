<template>
  <div>
    <q-dialog ref="linkProperties">
      <q-card class="full-width">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            Link statistics: {{linksStat?.[selectedEvent?.event_id] || 'n/a'}},
            #{{selectedEvent?.event_id}}
          </q-toolbar-title>
          <q-btn flat round icon="close" @click="$refs.linkProperties.hide()"/>
        </q-toolbar>
        <q-card-section>

          <template v-if="selectedEventConnection">
            <div class="text-subtitle1">
              <q-icon name="link" /> ({{ selectedEventConnection.source }} <q-icon name="east" /> {{ selectedEventConnection.target }})
            </div>

            <q-separator spaced />
          </template>

          <q-form @submit="saveEvent" ref="eventForm">
            <q-input v-model="selectedEvent.title" label="Event title" hint="Type event title for future analytics"
                     lazy-rules
                     :rules="[ val => val && val.length > 0 || 'Please type something']"
            />
            <q-checkbox v-model="selectedEvent.is_kpi" label="Included to KPIs" :true-value="1" :false-value="0"/>
            <q-separator spaced/>

            <div class="row">
              <q-btn label="Save" type="submit" color="primary"/>
              <q-space/>
              <q-btn label="Add to filter" color="secondary" icon="add" flat @click="addEventToFilter(selectedEvent)"/>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <ab-flow-designer v-if="isReady && currentDiagramSource?.id" :key="currentDiagramSource?.id" ref="diagramPreview" class="diagram-preview full-height full-width" :root="currentDiagramSource" :componentsList="componentsList"
                      :preview-mode="true"
                      @selected="onSelectBlock"
                      @update-block="updateBlock"
                      :link-labels="linksStatFlowLabels"
                      :canvas_size="{width:1000000, height: 1000000}"
                      :product_id="currentDiagramSource?.id"
    />
  </div>

</template>

<script>

import {designerComponentsList} from "@/components/DiagramDesigner/Editor/components/designerComponentsList";
import AbFlowDesigner from "ab-flow-designer/src/components/Designer/AbFlowDesigner"
import WidgetEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetEditorCmp.vue";
import WidgetPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetPropsCmp.vue";
import {WidgetProcessor} from "@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetProcessor";
import {computed, shallowRef} from "vue";
import {AppStyle} from "../../../../../common/db/AppStyle";
import {AnalyticsEvent} from "../../../../../common/db/AnalyticsEvent";

export default {
  name: "AnalyticsPagePreview",
  props: [
    'diagramId',
    'currentDiagramSource',
    'schemaSource',
    'schemaVersion',
    'codeVersion',
    'dateFrom',
    'dateTo',
    'requiredEvents',
    'appId',
    'moduleId',
    'appPlatform',
    'countries',
    'bigQuery',
    'appRemove',
    "utmSource",
    "utmMedium",
    "utmCampaign",
    "utmContent",
    "trafficSource",
  ],
  components: {AbFlowDesigner},
  inject: ['main'],
  provide: function() {
    return {
      designer: this,
      schemaSource: computed(() => this.schemaSource),
    }
  },
  data: () => ({
    componentsList: false,
    selectedEvent: false,
    selectedEventConnection: false,
    isReady: false,
    currentStyle: null,
    source: {},
    linksStatRaw: {}
  }),

  computed: {
    // Get links statistics
    linksStat() {
      return Object.fromEntries(Object.entries(this.linksStatRaw).map(([k, v]) => [
        k,
        `${v.unique_users_count}[${v.events_count}]${v.app_remove_count > 0 ? `/${v.app_remove_count}` : ''}`,
      ]));
    },
    // Get links statistics for flow labels
    linksStatFlowLabels() {
      return Object.fromEntries(Object.entries(this.linksStatRaw).map(([k, v]) => [
        k,
        `<tspan fill="#00ff00">${v.unique_users_count}</tspan><tspan fill="#0000ff">[${v.events_count}]</tspan>${v.app_remove_count > 0 ? `<tspan fill="#ff0000">/${v.app_remove_count}</tspan>` : ''}`,
      ]));
    },
  },

  async created() {

    // Store source for back compatibility
    this.source = this.currentDiagramSource

    // Create styles element
    this.currentStyle = new AppStyle()

    // Create styles element
    this.currentStyle.source = this.schemaSource?.styles

    // Load widgets list
    const widgets = this.schemaSource?.diagrams?.filter(d => d.diagram_type === 'widget')?.map(d => ({
      title: d.title,
      type: `Widget:${d.id}`,
      component: WidgetEditorCmp,
      properties: WidgetPropsCmp,
      processor: WidgetProcessor
    }))

    // Fill components list according to diagram type
    const list = [...[{
      title: "Widgets",
      type: 'g-widgets',
      purpose: ['ui'],
      expanded: true,
      children: widgets
    }], ...designerComponentsList]

    // Set components list
    this.componentsList = shallowRef(list)

    // Update statistics
    this.$watch("appPlatform", () => this.updateStatistics())

    // Update statistics
    this.updateStatistics();

    // Set ready
    this.isReady = true;
  },

  mounted() {
    // Checks if a `component_id` is present in the route parameters
    if (this.$route.params.component_id && Array.isArray(this.$refs?.diagramPreview?.selectedObjectIds)) {
      // Pre-select a specific component in the diagram when the page is loaded
      this.$refs.diagramPreview.selectedObjectIds.push(this.$route.params.component_id);
    }
  },

  methods: {

    /**
     * Update block
     * @param item
     */
    updateBlock(item) {
      const bl = this.currentDiagramSource?.children?.find(b => b.id === item.id)
      if(bl) {
        bl.x = item.params.x
        bl.y = item.params.y
      }
      console.log("Update block", bl, item)
    },

    /**
     * Add event to filter
     * @param event
     */
    async addEventToFilter(event) {
      // Validate form
      const res = await this.$refs.eventForm.validate()
      if(res) {
        // Save event first
        await this.saveEvent();

        // Add event id to requiredEvents
        const re = this.requiredEvents || []
        if(!re.includes(event.event_id)) re.push(event.event_id)

        // Store value
        this.main.setSettings(`${this.moduleId}.eventsList`, re)

        // Hide form
        this.$refs.linkProperties.hide();
      }
    },

    /**
     * Save event
     * @return {Promise<void>}
     */
    async saveEvent() {
      if (this.selectedEvent?.is_kpi === 1 && !this.selectedEvent?.position) {
        const {maxPosition} = await AnalyticsEvent.query()
          .select('MAX(position) as maxPosition')
          .where({module_id: this.moduleId, is_kpi: 1})
          .firstRaw();

        this.selectedEvent.position = (maxPosition || 0) + 1;
      } else if (this.selectedEvent?.is_kpi !== 1) {
        this.selectedEvent.position = 0;
      }

      // Save event
      await AnalyticsEvent.remote().save(this.selectedEvent)

      // Close form
      this.$refs.linkProperties.hide();
    },

    /**
     * On select block
     * @param data
     */
    async onSelectBlock(data) {

      // Get id
      const id = data?.[0]

      // Get link
      const link = this.currentDiagramSource?.children?.find(b => b.id === id);

      // Show link properties
      if(link?.type === 'link') {
        // Load event details
        this.selectedEvent = (await AnalyticsEvent.query().where({event_id: id, module_id: this.moduleId}).first()) || {
          module_id: this.moduleId,
          app_id: this.appId,
          event_id: id
        }

        // Assigns the source and target events of the selected link to the selectedEventConnection property.
        this.selectedEventConnection = {
          source: link?.properties?.connection?.source?.event || 'n/a',
          target: link?.properties?.connection?.target?.event || 'n/a',
        };

        // Show dialog
        this.$refs.linkProperties.show()
      }
    },

    /**
     * Update statistics
     * @return {Promise<void>}
     */
    async updateStatistics() {
      try {
        if(!(this.dateFrom && this.dateTo && this.currentDiagramSource?.id && this.codeVersion && this.schemaVersion)) return;
        this.$q.loading.show({message: "Updating statistics..."});
        this.linksStatRaw = await this.app.client.call(
          "app-statistics",
          "getNavigationStatistics",
          this.appPlatform,
          this.dateFrom,
          this.dateTo,
          this.diagramId,
          this.codeVersion,
          this.schemaVersion,
          this.requiredEvents,
          this.countries,
          this.bigQuery,
          this.appRemove ? 0 : 1,
          this.utmSource,
          this.utmMedium,
          this.utmCampaign,
          this.utmContent,
          this.trafficSource,
        ) || {};
      } finally {
        this.$q.loading.hide()
      }
    },

    /**
     * Get diagram
     * @param id
     * @return {unknown}
     */
    getDiagram(id) {
      const iId = parseInt(id)
      return this.schemaSource?.diagrams?.find(d => d.id === iId)
    }
  }

}

</script>
