<template>
  <q-field :label="label" :model-value="currentValue?.title">
    <template v-slot:control>
      {{currentValue?.title}}
    </template>
    <template v-slot:append>
      <q-btn icon="add" round flat @click="selectNode"/>
    </template>
  </q-field>
</template>

<script>

export default {
  name: "P3PNodePicker",
  inject: ['p3p'],
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    currentValue: {}
  }),
  created() {
    this.currentValue = this.modelValue || {}
  },
  methods: {
    async selectNode() {
      // Select node
      const node = await this.p3p.sendAction('select-node');

      // If node selected
      if(node) {
        // Set current node
        this.currentValue = node;

        // Emit update
        this.$emit('update:modelValue', this.currentValue)
      }
    }
  }
}
</script>
