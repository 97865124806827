<template>

  <modal-dialog ref="newComponent" title="New component" persistent>
    <component-form :edit-component="newComponent" @saved="componentAdded"/>
  </modal-dialog>

  <modal-dialog ref="uiTemplates" title="UI Templates" persistent full-width full-height>
    <ui-templates-diagrams-list @paste-ui-template="onPasteUiTemplate" />
  </modal-dialog>

  <ui-prop-string name="title" title="Component title" :block="block"/>

  <template v-if="moduleType !== 'server'">
    <ui-prop-checkbox name="isEmbedded" title="Embedded diagram" :block="block"/>
    <ui-prop-checkbox name="isModalDialog" title="Modal dialog" :block="block"/>
  </template>

  <ui-modal-props v-if="block?.properties?.isModalDialog === 1" :block="block" />

  <div class="row q-gutter-x-sm" v-if="!isExternalSource">
    <ui-prop-select class="col" :block="block" name="diagramComponentId" title="Diagram component" :options="componentsList"/>
    <q-btn v-if="block.properties?.diagramComponentId" icon="edit" flat class="q-mt-md bg-blue-1" size="sm" @click="editComponent"></q-btn>

    <q-btn-dropdown icon="add" class="add-action q-mt-md bg-blue-1" size="sm" flat>
      <q-list>
        <q-item clickable v-close-popup @click="addComponent">
          <q-item-section>
            <q-item-label>New</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable v-close-popup @click="pasteFromTemplate">
          <q-item-section>
            <q-item-label>From template</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-btn-dropdown>
  </div>
  <ui-prop-p3p-node-picker v-else :block="block" type="diagram" name="diagramComponentId" title="Diagram"/>

  <ui-prop-arguments v-if="targetDiagramId" :block="block" name="arguments" title="Diagram arguments"
                     :target-block-id="block.id" :diagram-only="true"/>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import {Diagram} from "@/../../common/db/Diagram.js"
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {nanoid} from "nanoid";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiModalProps from '@/components/DiagramDesigner/Editor/properties/UiModalProps.vue';
import ModalDialog from "@/components/ModalDialog/ModalDialog.vue";
import ComponentForm from "@/pages/workspace/diagrams/components/ComponentForm.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropP3pNodePicker from "@/components/DiagramDesigner/Editor/properties/UiPropP3pNodePicker.vue";

export default {
  inject: ['canvas', 'product_id', 'module_id'],
  mixins: [propertiesMixins],
  components: {
    UiPropP3pNodePicker,
    ComponentForm, ModalDialog, UiModalProps, UiPropCheckbox, UiPropArguments, UiPropString, UiPropSelect},
  props: ['block'],
  name: "DiagramComponentPropsCmp",
  data: () => ({
    tab: "props",
    newComponent:{}
  }),

  computed: {

    /**
     * Module type
     * @return {*}
     */
    moduleType() {
      return this.currentModule?.currentModule?.type;
    },

    /**
     * Return diagram id
     * @return {Document.diagramComponentId}
     */
    targetDiagramId() {
      return this.block.properties?.diagramComponentId;
    },

    // Return all components from the store
    componentsList() {
      return this.wait("componentsList", async () => {
        return (await Diagram.query().where("app_id", this.canvas.product_id).where("module_id", this.module_id).where("status", "active").get()).filter(e => e.id !== this.diagram_id).map(el => ({
          label: el.title,
          value: el.id
        }))
      }, [])
    },
  },

  methods: {

    /**
     * Component added
     * @param cmp
     */
    componentAdded(cmp) {
      // Set new component
      this.mutate(this.block).properties.diagramComponentId = cmp.id;

      // Hide dialog
      this.$refs.newComponent.hide()
    },

    /**
     * Add new component
     */
    addComponent() {
      // Set new component
      this.newComponent = {
        app_id: this.product_id,
        module_id: this.module_id,
        unique_id: nanoid(10),
        diagram_type: 'component',
        version: 0,
        status: 'active',
      }

      // Open dialog window
      this.$refs.newComponent.show()
    },

    /**
     * Edit component
     */
    async editComponent() {

      // Load component
      this.newComponent = await Diagram.find(this.block.properties.diagramComponentId)

      // Open dialog window
      this.$refs.newComponent.show()
    },

    /**
     * Show the UI Templates modal dialog.
     */
    pasteFromTemplate() {
      this.$refs.uiTemplates.show();
    },

    /**
     * Handles the paste UI template action.
     * @param {Object} cmp - The component to be pasted.
     */
    onPasteUiTemplate(cmp) {
      this.$q.dialog({
        title: 'Paste confirmation',
        message: `Are you sure want to paste diagram?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {

        try {
          await Diagram.remote().subscribe('diagram', {id: cmp.id});

          const newComponent = await Diagram.remote().call('app', 'duplicateDiagram', {
            diagram_id: cmp.id,
            app_id: this.product_id,
            module_id: this.module_id,
          });

          // show toast
          if (newComponent) {
            this.$q.notify({
              message: 'Diagram pasted',
              color: 'positive',
              icon: 'check',
              position: 'top'
            });

            this.componentAdded(newComponent);

            this.$refs?.uiTemplates?.hide();
          }
        } catch (e) {
          this.$q.notify({
            message: e?.error || e?.message || 'Error pasting diagram',
            color: 'negative',
            icon: 'error',
            position: 'top'
          })
        }
      })
    },
  },
}

</script>

<style lang="scss" scoped>
.add-action {
  &:deep(.q-btn-dropdown__arrow) {
    display: none;
  }
}
</style>
