<template>

  <div>
    <ui-prop-string name="title" title="Block title" :block="block" />

    <ui-prop-dynamic-string :block="block" title="Email" name="email" hint="Separated by commas" />
    <ui-prop-dynamic-string :block="block" title="List ID" name="list_id" hint="Separated by commas" />
    <ui-prop-select title="Action" name="action" :block="block" :options="actions" default-value="add" />

    <ui-data-props
      v-if="block?.properties?.action === 'add'"
      title="Custom fields"
      name="customFields"
      :default-schema="defaultSchema"
      :block="block"
    />
  </div>

</template>

<script>
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';

export default {
  components: {UiDataProps, UiPropSelect, UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "MailingListManagerPropsCmp",

  data() {
    return {
      actions: [
        {label: 'Add', value: 'add'},
        {label: 'Remove', value: 'remove'},
      ],

      defaultSchema: [
        {
          title: "Field",
          name: "field",
          type: "string"
        },
        {
          title: "Value",
          name: "value",
          type: "dynamic-string"
        },
      ],
    };
  },
}

</script>
