/**
 * Defines a list of marketing pixel events.
 * This constant provides a structured list of marketing events that can be tracked across different platforms.
 * Each event is represented as an object with a title, a value, and a platforms object that maps platform names
 * to their respective event identifiers. Some events may also be marked as hidden, indicating they should not
 * be displayed or used in certain contexts.
 *
 * @type {Array<Object>}
 *
 * @example
 * // Accessing the 'ViewContent' event for Facebook
 * const viewContentFacebook = marketingPixelEventsList.find(event => event.title === 'ViewContent').platforms.facebook;
 *
 * @example
 * // Filtering out hidden events
 * const visibleEvents = marketingPixelEventsList.filter(event => !event.hidden);
 */
export const marketingPixelEventsList = [
  {
    title: 'ViewContent',
    value: 'ViewContent',
    platforms: {
      facebook: 'ViewContent',
      tiktok: 'ViewContent',
      firebase: 'a2u_ViewContent',
      postback: 'ViewContent',
    }
  },
  {
    title: 'Search',
    value: 'Search',
    platforms: {
      facebook: 'Search',
      tiktok: 'Search',
      firebase: 'a2u_Search',
      postback: 'Search',
    }
  },
  {
    title: 'Lead',
    value: 'Lead',
    platforms: {
      facebook: 'Lead',
      tiktok: 'SubmitForm',
      firebase: 'a2u_Lead',
      postback: 'Lead',
    }
  },
  {
    title: 'PlaceAnOrder',
    value: 'PlaceAnOrder',
    platforms: {
      facebook: 'AddToCart',
      tiktok: 'PlaceAnOrder',
      firebase: 'a2u_PlaceAnOrder',
      postback: 'PlaceAnOrder',
    }
  },
  {
    title: 'Purchase',
    value: 'Purchase',
    platforms: {
      facebook: 'Purchase',
      tiktok: 'CompletePayment',
      firebase: 'a2u_Purchase',
      postback: 'Purchase',
    },
    hidden: true,
  },
];
