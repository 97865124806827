<template>
  <q-card class="q-mt-sm">
    <q-dialog ref="selectDlg">
      <q-card class="full-width">
        <q-card-section class="bg-primary text-white row">
          Select variable
          <q-space/>
          <q-btn icon="close" flat round @click="$refs.selectDlg.hide();"/>
        </q-card-section>
        <q-card-section>
          <q-tree no-selection-unset node-key="id" :nodes="nodesTree" v-model:selected="currentValue"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-card-section class="bg-primary text-white" v-if="title">
      {{title}}
      <div>{{hint}}</div>
    </q-card-section>
    <q-card-section>
      <q-field label="Value" stack-label class="col">
        <template v-slot:append>
          <q-btn icon="list" flat size="sm" @click="$refs.selectDlg.show()"/>
        </template>

        <div class="text-black cursor-pointer overflow-hidden">
          {{ selectedValue }}
        </div>
      </q-field>
    </q-card-section>
  </q-card>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {StorageNode} from '../../../../../../common/db/StorageNode';

export default {
  emits: ['updated'],
  props: {
    title: {
      default: false
    },
    parentId: {
      required: false,
      default: 0,
    },
  },
  mixins: [propertiesMixins],
  name: "UiPropTreeStorageSelector",
  data() {
    return {};
  },

  computed: {
    nodes() {
      return this.wait('nodes', StorageNode.query().where({
        app_id: this.product_id,
        module_id: this.module_id,
        block_id: 'tree-storage',
      }).get(), []);
    },

    nodesTree() {
      // Store nodes recursively as tree with key, label, children
      const tree = (nodes, parent_id) => nodes.filter(node => node.parent_id === parent_id).map(node => ({
        id: node.id,
        label: node.name,
        children: tree(nodes, node.id),
      }))

      // Return tree
      return tree(this.nodes, this.parentId);
    },

    selectedValue() {
      if (!this.currentValue) {
        return '';
      }

      const path = [];

      let id = this.currentValue;

      while (id) {
        const node = this.nodes.find(n => n.id === id);

        if (!node) {
          break;
        }

        path.push(node.name);

        id = node.parent_id;
      }

      return path.reverse().join('.');
    },
  },
}

</script>
