<template>
  <q-page class="column">
    <q-tabs v-model="tab" >
      <q-tab name="structure" label="Structure" no-caps />
      <q-tab name="content" label="Content" no-caps />
      <q-tab name="settings" label="Settings" no-caps />
    </q-tabs>

    <q-tab-panels v-model="tab">
      <q-tab-panel name="structure">
        <storage-tree :app-id="appId" :module-id="moduleId" block_id="tree-storage" mode="tree-storage" />
      </q-tab-panel>
      <q-tab-panel name="content">
        <tree-storage-content :schema="nodes" v-model="model" />

        <q-btn @click="saveContent" label="Save" color="primary"/>
      </q-tab-panel>
      <q-tab-panel name="settings">Coming soon...</q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>
import StorageTree from "@/pages/workspace/storage/StorageTree.vue";
import {StorageNode} from '../../../../../common/db/StorageNode';

export default {
  name: "TreeStoragePage",
  components: {StorageTree},
  data() {
    return {
      tab: 'structure',
      model: {},
    };
  },

  computed: {
    appId() {
      return this.$route.params.app_id;
    },
    moduleId() {
      return this.$route.params.module_id;
    },

    nodes() {
      return this.wait("nodes", async () => {

        // Get app nodes, it is tree structure id, parent_id
        const nodes = await StorageNode.query()
          .where({
            app_id: this.appId,
            module_id: this.moduleId,
            block_id: 'tree-storage',
          })
          .get()

        // Store nodes recursively as tree with key, label, children
        const tree = (nodes, parent_id) => nodes.filter(node => node.parent_id === parent_id).map(node => ({
          id: node.id,
          type: node.type,
          name: node.name,
          parent_id: node.parent_id,
          is_array: node.is_array === 1,
          children: [
            ...(node.type === 'object' ? [{
              name: 'id',
              parent_id: node.parent_id,
            }] : []),
            ...tree(nodes, node.id),
          ]
        }))

        // Return tree
        return tree(nodes, 0)

      }, [])
    }
  },

  methods: {
    async loadContent() {
      try {
        this.model = await this.app.client.call('tree-storage', 'getData', {
          appId: this.appId,
          moduleId: this.moduleId,
        }) || {};
      } catch (e) {
        console.error('Failed to load content', e);

        this.$q.notify({
          color: 'negative',
          message: 'Failed to load content',
        })
      }
    },

    async saveContent() {
      try {
        await this.app.client.call('tree-storage', 'saveData', {
          appId: this.appId,
          moduleId: this.moduleId,
          data: this.model,
        });
      } catch (e) {
        console.error('Failed to save content', e);

        this.$q.notify({
          color: 'negative',
          message: 'Failed to save content',
        })
      }
    },
  },

  async created() {
    await this.loadContent();
  },
}
</script>
