/**
 * App model
 */
import _ from "lodash";
import {nanoid} from "nanoid";
import {Localization} from './Localization.js';
import {LocalizationMessage} from './LocalizationMessage.js';
import {AccessModel} from './AccessModel.js';

export class StorageNode extends AccessModel {
    static entity = 'storage_nodes'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        parent_id: "int",
        title: "string",
        name: "string",
        type: "string",
        is_array: "int",
        update_state: "int",
        is_argument: "int",
        is_reference: "int",
        value: "json",
        app_id: "int",
        db_table: "string",
        module_id: "int",
        block_id: "string",
        unique_id: "string",
        is_test_value: "int",
        is_localizable: "int",
        locale_alias: "string",
        is_injectable: "int",
        tree_storage_node: "int",
    }

    /**
     * Clears the cache for the current app\_id using the schemaCache plugin.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-storage': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => StorageNode.getList(app_id),
            },
            'module-storage': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => StorageNode.query().where({module_id}).get(),
            },
        }
    }

    /**
     * Get list
     */
    static getList(app_id) {
        return StorageNode.query().where({app_id}).get()
    }

    /**
     * Duplicate storage node
     * @param {number} from_module_id
     * @param {number} to_app_id
     * @param {number} to_module_id
     * @param {number} block_id
     * @param {number} to_block_id
     * @return {Promise<void>}
     */
    static async duplicate(from_module_id, to_app_id, to_module_id, block_id, to_block_id) {

        // Nodes
        const nodes = await this.query().where({module_id: from_module_id, block_id}).order('parent_id asc').get();

        // Map of nodes
        const nodesMap = new Map();

        const result = [];

        // Save nodes with new block id
        for(const node of nodes) {
            // Skip nodes with parent that doesn't exist
            if (node.parent_id !== 0 && !nodesMap.has(node.parent_id)) {
                continue;
            }

            let locale_alias = '';
            let is_localizable = 0;

            // Duplicate localization
            if (node.locale_alias && node.is_localizable) {
                const locale = await Localization.query().where({
                    module_id: from_module_id,
                    alias: node.locale_alias,
                }).first();

                // If locale exists
                if (locale) {
                    // Set localizable flag
                    is_localizable = 1;

                    // Duplicate locale
                    const newLocale = await Localization.remote().save({
                        ...locale,
                        id: null,
                        module_id: to_module_id,
                        alias: nanoid(10),
                    });

                    // Set new locale alias
                    locale_alias = newLocale.alias;

                    // Duplicate messages
                    const messages = await LocalizationMessage.query().where({
                        localization_id: locale.id,
                    }).get();

                    for (const message of messages) {
                        await LocalizationMessage.remote().save({
                            ...message,
                            localization_id: newLocale.id,
                            id: null,
                        });
                    }
                }
            }

            const newNode = await StorageNode.remote().save({
                ...node,
                app_id: to_app_id,
                block_id: to_block_id,
                module_id: to_module_id,
                locale_alias,
                is_localizable,
                parent_id: node.parent_id === 0 ? 0 : nodesMap.get(node.parent_id),
                id: undefined,
            });

            // Save node id
            nodesMap.set(node.id, newNode.id);

            result.push(newNode);
        }

        return result;
    }

    /**
     * Get argument nodes from tree
     * @param module_id
     * @param block_id
     * @param filter
     * @return {Promise<void>}
     */
    static async getArguments(module_id, block_id, filter) {

        // Results list
        const resList = []

        // Nodes
        const nodes = await this.query().where({module_id, block_id}).get()

        // Store nodes recursively as tree with key, label, children
        const tree = (node, path) => {

            // Set value
            if(path && (node.is_argument || (filter && filter(node)) )) resList.push({
                title: node.title,
                name: path,
                nodeId: node.id,
                type: node.type,
                is_array: node.is_array,
                is_reference: node.is_reference,
            });

            // Get node children
            for(const ch of nodes.filter(n => n.parent_id === node.id)) {
                tree(ch, (path ? path + "." : "") + ch.name)
            }
        };

        // Return tree
        tree({id: 0}, "")

        // Return result data
        return resList;
    }


    /**
     * Get tree
     * @returns {Promise<void>}
     */
    static async getTree(module_id, block_id, convertResourceValue) {
        return this.getAppTree(0, module_id, block_id, convertResourceValue)
    }

    /**
     * Get tree
     * @returns {Promise<void>}
     */
    static async getAppTree(app_id, module_id, block_id, convertResourceValue) {
        // Load nodes
        const nodes = await this.query().where({
            ...(app_id ? {app_id} : {}),
            ...(module_id ? {module_id} : {}),
            block_id
        }).get()

        return this.generateStorageTree(nodes, convertResourceValue);
    }

    /**
     * Generates a storage tree from the given nodes.
     *
     * @param {Array} nodes - The list of nodes to generate the tree from.
     * @param {Function} [convertResourceValue] - Optional function to convert the resource value.
     * @returns {Object} The generated storage tree.
     */
    static generateStorageTree(nodes, convertResourceValue) {

        // Result data
        const resData = {};

        // Store nodes recursively as tree with key, label, children
        const tree = (node, path) => {

            // Set value
            if(path) _.set(resData, path, convertResourceValue ? convertResourceValue(node) : node?.value);

            // Get node children
            for(const ch of nodes.filter(n => n.parent_id === node.id)) {
                tree(ch, (path ? path + "." : "") + ch.name)
            }
        };

        // Return tree
        tree({id: 0}, "")

        // Return result data
        return resData;
    }

    /**
     * Add child node
     * @param name
     * @param title
     * @param type
     * @param value
     * @return {Promise<void>}
     */
    async addChild(name, title, type, value) {
        return StorageNode.remote().save({
            parent_id: this.id,
            title: title,
            name,
            type,
            value,
            app_id: this.app_id,
            module_id: this.module_id,
            block_id: this.block_id,
            unique_id: nanoid(10)
        })
    }

    /**
     * Get parenst list
     * @return {Promise<void>}
     */
    async parents() {

        // Result list
        const resList = []

        // Get parent
        let parent = await StorageNode.find(this.parent_id);

        // Get parent list
        while(parent) {
            resList.push(parent)
            parent = await StorageNode.find(parent.parent_id)
        }

        // Return result
        return resList
    }

    /**
     * Retrieves the full path of a storage node by traversing its parent nodes.
     *
     * @param {number} itemId - The ID of the storage node to start from.
     * @returns {Promise<Array>} - A promise that resolves to an array representing the full path of the node.
     */
    static async getFullPath(itemId) {
        const chain = [];
        let currentId = itemId;

        while (true) {
            const item = await StorageNode.find(currentId);
            if (!item) break;

            chain.unshift({
                ...item,
                pathKey: `${item.module_id}-${item.block_id}-${item.name}`
            });

            if (item.parent_id === 0) break;
            currentId = item.parent_id;
        }

        return chain;
    }

    /**
     * Creates a chain of parent nodes in the target application and module.
     *
     * @param {Array} parentChain - The chain of parent nodes to create.
     * @param {number} targetAppId - The ID of the target application.
     * @param {number} targetModuleId - The ID of the target module.
     * @returns {Promise<Object>} - An object containing the last parent ID and the created nodes.
     */
    static async createParentChain(parentChain, targetAppId, targetModuleId) {
        let currentParentId = 0;
        const created = [];

        for (const parent of parentChain) {
            let node = await StorageNode.query().where({
                module_id: targetModuleId,
                block_id: parent.block_id,
                name: parent.name,
                parent_id: currentParentId,
                app_id: targetAppId
            }).first();

            if (!node) {
                let locale_alias = '';
                let is_localizable = 0;

                if (parent.locale_alias && parent.is_localizable) {
                    const newAlias = await Localization.duplicate(parent.module_id, targetModuleId, parent.locale_alias);

                    if (newAlias) {
                        locale_alias = newAlias;
                        is_localizable = 1;
                    }
                }

                node = await StorageNode.save({
                    ...parent,
                    app_id: targetAppId,
                    module_id: targetModuleId,
                    parent_id: currentParentId,
                    locale_alias,
                    is_localizable,
                    id: undefined,
                });
                created.push(node);
            }

            currentParentId = node.id;
        }

        return { lastParentId: currentParentId, created };
    }

    /**
     * Copies a storage node along with its parent nodes to a target application and module.
     *
     * @param {number} itemId - The ID of the storage node to copy.
     * @param {number} targetAppId - The ID of the target application.
     * @param {number} targetModuleId - The ID of the target module.
     * @returns {Promise<number|null>} - The ID of the copied storage node, or null if the source item is not found.
     */
    static async copyItemWithParents(itemId, targetAppId, targetModuleId) {
        const sourceItem = await StorageNode.find(itemId);

        if (!sourceItem) {
            return null;
        }

        const parentChain = await StorageNode.getFullPath(sourceItem.parent_id);

        const { lastParentId } = await StorageNode.createParentChain(
          parentChain,
          targetAppId,
          targetModuleId
        );

        const existingItem = await StorageNode.query().where({
            name: sourceItem.name,
            parent_id: lastParentId,
            app_id: targetAppId,
            module_id: targetModuleId,
            block_id: sourceItem.block_id,
        }).first();

        if (existingItem) {
            return existingItem.id;
        }

        let locale_alias = '';
        let is_localizable = 0;

        if (sourceItem.locale_alias && sourceItem.is_localizable) {
            const newAlias = await Localization.duplicate(sourceItem.module_id, targetModuleId, sourceItem.locale_alias);

            if (newAlias) {
                locale_alias = newAlias;
                is_localizable = 1;
            }
        }

        return await StorageNode.save({
            ...sourceItem,
            app_id: targetAppId,
            module_id: targetModuleId,
            parent_id: lastParentId,
            locale_alias,
            is_localizable,
            id: undefined,
        });
    }
}
