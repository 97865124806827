import axios from 'axios'
import _ from "lodash";

/**
 * P3P Service
 */
export class P3PNodeService {

    /**
     * Class constructor
     */
    constructor(token) {
        // 3p url
        this.p3pUrl = process.env.VUE_APP_P3P_API_URL;

        // Properties
        this.properties = {};

        // Store token
        this.token = token;
    }

    /**
     * Load properties
     * @return {Promise<void>}
     */
    async init() {

        // Load content
        const content = await this.loadData();

        // Load properties
        this.properties = content || {}

        // Return self
        return this;
    }

    /**
     * Save properties
     * @return {Promise<void>}
     */
    async saveProperties(props) {

        // Apply props and current properties
        _.merge(this.properties, props);

        // Save properties
        return this.saveData(this.properties);
    }

    /**
     * Load data
     * @return {Promise<*>}
     */
    async loadData() {
        return (await axios.post(this.p3pUrl + "/content/load", [this.token]))?.data
    }

    /**
     * Save data
     * @param data
     * @return {Promise<void>}
     */
    async saveData(data) {
        return axios.post(this.p3pUrl + "/content/store", [this.token, data])
    }

    /**
     * Get node resource url
     * @return {Promise<void>}
     * @param token
     */
    async resourceUrl(token) {
        return (await axios.post(this.p3pUrl + "/content/resource", [token]))?.data
    }
}
