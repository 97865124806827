<template>

  <div>

    <ui-prop-string name="title" title="Operation title" :block="block"/>

    <ui-prop-checkbox v-if="!isTreeStorageSelected" class="q-my-sm" name="physicalDelete" title="Physically delete" :block="block" :default-value="0"/>

    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbOptions"/>

    <ui-prop-select v-if="block?.properties?.dbId && !isTreeStorageSelected" :block="block" name="tableId" title="Table" :options="tablesList"/>

    <ui-prop-value-selector v-if="block?.properties?.tableId || isTreeStorageSelected" :block="block" name="recordId" only-var-type="string" title="Record id" :parent-diagram-id="diagram_id"/>

  </div>

</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import {dbListMixin} from '../dbListMixin';

export default {
  mixins: [propertiesMixins, dbListMixin],
  components: {UiPropCheckbox, UiPropValueSelector, UiPropSelect, UiPropString},
  inject: ["currentModule"],
  props: ['block'],
  name: "DbDeletePropsCmp",

  computed: {}
}

</script>
