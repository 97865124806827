<template>
  <q-tree class="col-12 col-sm-6"
          :nodes="foldersTree"
          v-model:expanded="expanded"
          v-model:selected="selected"
          node-key="value"
          default-expand-all
  />
</template>

<script>
import {Folder} from '../../../../../common/db/Folder';

export default {
  name: 'FolderPicker',

  props: {
    type: {
      required: true,
      type: String,
    },
    app_id: {
      required: false,
      type: Number,
      default: 0,
    },
    module_id: {
      required: false,
      type: Number,
      default: 0,
    },
    modelValue: {
      required: true,
      type: Number,
    },
    disabledFolders: {
      required: false,
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      expanded: [0],
    };
  },

  computed: {
    /**
     * Computed property for the selected folder.
     * It uses the `modelValue` prop for its value.
     * When the value changes, it emits an `update:modelValue` event.
     */
    selected: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

    /**
     * Computed property to get the parent folder ID from the route parameters.
     * If the `folder_id` parameter is not present, it defaults to 0.
     *
     * @returns {number} The parent folder ID.
     */
    parentId() {
      return Number(this.$route.params.folder_id || 0);
    },

    /**
     * Computed property to fetch folders from the database.
     * It uses the `Folder` model to query the database with the specified filters.
     * The result is cached using the `wait` method.
     *
     * @returns {Array} The list of folders.
     */
    folders() {
      return this.wait('folders', Folder.query().where({
        type: this.type,
        app_id: this.app_id,
        module_id: this.module_id,
      }).get(), []);
    },

    /**
     * Computed property to generate a tree structure from the folders.
     * It maps each folder to its corresponding parent folder to create a nested tree.
     *
     * @returns {Array} The tree structure of folders.
     */
    foldersTree() {
      const itemMap = {}

      // Map each folder to an object with value, label, and children properties
      this.folders.forEach(item => {
        itemMap[item.id] = {
          value: item.id,
          label: item.name,
          disabled: this.disabledFolders.includes(item.id),
          children: [],
        }
      });

      const tree = []
      // Populate the tree structure by linking child folders to their parent folders
      this.folders.forEach(item => {
        if (item.parent_id) {
          if (itemMap[item.parent_id]) {
            itemMap[item.parent_id].children.push(itemMap[item.id])
          }
        } else {
          tree.push(itemMap[item.id])
        }
      })

      // Return the tree structure with a root node
      return [{
        value: 0,
        label: 'Root',
        children: tree,
      }]
    },
  },
}
</script>
