import axios from 'axios'
import _ from "lodash";

/**
 * P3P Service
 */
export class P3PNodeService {

    /**
     * Class constructor
     */
    constructor(token) {
        // Ensure the service is initialized properly
        this.listeners = {};

        // 3p url
        this.p3pUrl = process.env.VUE_APP_P3P_API_URL;

        // Properties
        this.properties = {};

        // Store token
        this.token = token;
    }

    /**
     * Load properties
     * @return {Promise<void>}
     */
    async init() {

        // Load content
        const content = await this.loadData();

        // Load properties
        this.properties = content || {}

        // Add message listener
        window.addEventListener('message', (data) => this.onMessage(data));

        // Return self
        return this;
    }

    /**
     * On message
     * @param event
     */
    onMessage(event) {
        const id = event.data?.id
        const payload = event.data?.payload
        if (id && this.listeners[id]) {
            this.listeners[id](payload);
        }
    }

    /**
     * Sends a message to the parent application.
     * @param id
     * @param {string} action - The type of the message.
     * @param {object} payload - The data to send.
     */
    sendMessage(id, action, payload = {}) {

        // Send to parent
        if (window.parent) {
            const message = {
                // Unique id
                id,
                action,
                payload,
                source: 'iframe', // Identify the source of the message
            };
            window.parent.postMessage(message, '*'); // Replace '*' with specific origin if needed
        } else {
            console.error('No parent window detected.');
        }
    }

    /**
     * Removes all registered message listeners.
     */
    removeListeners() {
        window.removeEventListener('message', this.onMessage);
        this.listeners = {};
    }

    /**
     * Select node in tree
     * @param action
     * @param payload
     * @param timeout
     */
    async sendAction(action, payload, timeout = 100000) {

        // Wait for results
        return new Promise((resolve, reject) => {
            // Timeout
            let tm = setTimeout(() => {
                reject('Timeout')
            }, timeout)

            // Generate unique id
            const id = Math.random().toString(36).substr(2, 9);

            // Add listener
            this.listeners[id] = (data) => {
                clearTimeout(tm)
                resolve(data)
            }

            // Send message
            this.sendMessage(id, action, payload);
        })
    }

    /**
     * Save properties
     * @return {Promise<void>}
     */
    async saveProperties(props) {

        // Apply props and current properties
        _.merge(this.properties, props);

        // Save properties
        return this.saveData(this.properties);
    }

    /**
     * Load data
     * @return {Promise<*>}
     */
    async loadData() {
        return (await axios.post(this.p3pUrl + "/content/load", [this.token]))?.data
    }

    /**
     * Save data
     * @param data
     * @return {Promise<void>}
     */
    async saveData(data) {
        return axios.post(this.p3pUrl + "/content/store", [this.token, data])
    }

}
