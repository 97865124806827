<template>
  <q-card class="q-mb-md">
    <q-card-section>
      <div class="row items-center justify-between q-mb-md">
        <div class="text-h6">{{ field.name }} <q-icon name="data_array"/></div>
      </div>

      <div v-for="(item, index) in localValue" :key="index" class="q-ml-md q-mb-md">
        <q-card bordered>
          <q-card-section>
            <div class="row items-center justify-between q-mb-sm">
              <div class="text-subtitle1" v-text="`${field.name}[${index}]`" />
              <q-btn
                flat
                round
                color="negative"
                icon="delete"
                @click="removeItem(index)"
              />
            </div>

            <template v-if="isObjectArray">
              <template v-for="childField in field.children" :key="childField.id">
                <tree-storage-field
                  :field="childField"
                  :value="item"
                  @update="val => updateItem(index, val)"
                />
              </template>
            </template>

            <template v-else>
              <tree-storage-simple-field
                :field="field"
                :value="localValue[index]"
                @update="val => updateItem(index, val)"
              />
            </template>
          </q-card-section>
        </q-card>
      </div>
      <div class="row items-center justify-between q-mb-md">
        <q-btn
          flat
          color="primary"
          icon="add"
          label="Add Item"
          @click="addItem"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import {nanoid} from 'nanoid';

export default {
  name: 'TreeStorageArrayField',

  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      localValue: this.value || []
    }
  },

  computed: {
    /**
     * Checks if the field is an array of objects.
     * @returns {boolean} True if the field has children and the length of children is greater than 0.
     */
    isObjectArray() {
      return this.field.children && this.field.children.length > 0;
    },
  },

  watch: {
    value: {
      handler(newVal) {
        this.localValue = newVal || []
      },
      deep: true
    }
  },

  methods: {
    /**
     * Returns the default value for a new item in the array.
     * If the field is an array of objects, it creates an object with default values for each child field.
     * Otherwise, it returns a default value based on the field type.
     *
     * @returns {Object|string|number} The default value for the new item.
     */
    getDefaultValue() {
      if (this.isObjectArray) {
        const item = {
          id: nanoid(10),
        }
        this.field.children.forEach(child => {
          if (child.name === 'id') {
            return;
          }
          item[child.name] = child.is_array ? [] : child.type === 'object' ? {} : ''
        })
        return item
      } else {
        switch (this.field.type) {
          case 'bool':
          case 'int':
          case 'float':
            return 0
          default:
            return ''
        }
      }
    },

    /**
     * Adds a new item to the array.
     * The new item is created using the default value based on the field type.
     * Emits an 'update' event with the new array value.
     */
    addItem() {
      const newValue = [...this.localValue, this.getDefaultValue()]
      this.$emit('update', newValue)
    },

    /**
     * Updates an item in the array at the specified index.
     * Emits an 'update' event with the new array value.
     *
     * @param {number} index - The index of the item to update.
     * @param {Object|string|number} newValue - The new value for the item.
     */
    updateItem(index, newValue) {
      const updatedValue = [...this.localValue]
      updatedValue[index] = newValue
      this.$emit('update', updatedValue)
    },

    /**
     * Removes an item from the array at the specified index.
     * Prompts the user for confirmation before deletion.
     * Emits an 'update' event with the new array value if confirmed.
     *
     * @param {number} index - The index of the item to remove.
     */
    removeItem(index) {
      this.$q.dialog({
        title: 'Delete item',
        message: 'Are you sure you want to delete this item?',
        ok: 'Yes',
        cancel: 'No'
      }).onOk(() => {
        this.$emit('update', this.localValue.filter((_, i) => i !== index));
      })
    }
  },
}
</script>
