
/**
 * P3P Service
 */
export class P3PService {

    /**
     * Class constructor
     */
    constructor() {
        // Ensure the service is initialized properly
        this.listeners = {};
    }

    /**
     * Load properties
     * @return {Promise<void>}
     */
    init() {

        // Add message listener
        window.addEventListener('message', (data) => this.onMessage(data));

        // Return self
        return this;
    }

    /**
     * On message
     * @param event
     */
    onMessage(event) {
        const id = event.data?.id
        const payload = event.data?.payload
        if (id && this.listeners[id]) {
            this.listeners[id](payload);
        }
    }

    /**
     * Sends a message to the parent application.
     * @param id
     * @param {string} action - The type of the message.
     * @param {object} payload - The data to send.
     */
    sendMessage(id, action, payload = {}) {

        // Send to parent
        if (window.parent) {
            const message = {
                // Unique id
                id,
                action,
                payload,
                source: 'iframe', // Identify the source of the message
            };
            window.parent.postMessage(message, '*'); // Replace '*' with specific origin if needed
        } else {
            console.error('No parent window detected.');
        }
    }

    /**
     * Removes all registered message listeners.
     */
    removeListeners() {
        window.removeEventListener('message', this.onMessage);
        this.listeners = {};
    }

    /**
     * Select node in tree
     * @param action
     * @param payload
     * @param timeout
     */
    async sendAction(action, payload, timeout = 100000) {

        // Wait for results
        return new Promise((resolve, reject) => {
            // Timeout
            let tm = setTimeout(() => {
                reject('Timeout')
            }, timeout)

            // Generate unique id
            const id = Math.random().toString(36).substr(2, 9);

            // Add listener
            this.listeners[id] = (data) => {
                clearTimeout(tm)
                resolve(data)
            }

            // Send message
            this.sendMessage(id, action, payload);
        })
    }
}
