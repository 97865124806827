<template>
  <q-splitter class="full-height" v-model="splitter">
    <template #before>
      <div class="q-pa-sm">
        <div class="text-subtitle1">Choose the relevant section</div>

        <div class="column q-gutter-md q-mt-sm">
          <q-tree
            :nodes="sectionsTree"
            v-model:expanded="expanded"
            v-model:selected="selected"
            node-key="value"
            default-expand-all
            no-selection-unset
          />
        </div>
      </div>
    </template>

    <template #after>
      <div class="q-px-md q-pt-sm">
        <div class="text-subtitle1">Choose the desired fragment (click to paste)</div>

        <div class="row q-gutter-x-md q-gutter-y-lg q-mt-sm">
          <div
            v-for="fragment in fragments"
            :key="fragment"
            class="ui-template-preview dg-media cursor-pointer"
            @click="pasteBlock(fragment)"
          >
            <ab-flow-components-renderer
              :items="[fragment.source]"
              preview-mode
            />
          </div>
        </div>
      </div>
    </template>
  </q-splitter>
</template>

<script>
import AbFlowComponentsRenderer from 'ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer';
import {Diagram} from '../../../../../common/db/Diagram';
import {Folder} from '../../../../../common/db/Folder';

export default {
  name: 'UiTemplatesFragmentsList',

  components: {AbFlowComponentsRenderer},

  emits: ['paste-ui-template'],

  data() {
    return {
      splitter: 20,
      templatesProjectId: process.env.VUE_APP_UI_TEMPLATES_MODULE_ID,
      diagrams: [],
      folders: [],
      expanded: [0],
      selected: '',
    };
  },

  computed: {
    /**
     * Computes a map of diagrams grouped by their folder ID.
     *
     * @returns {Map<number, Array>} A map where the key is the folder ID and the value is an array of diagrams in that folder.
     */
    diagramsByFolder() {
      return this.diagrams.reduce((acc, d) => {
        const folderId = d?.folder_id || 0;

        if (!acc.has(folderId)) {
          acc.set(folderId, [])
        }

        acc.get(folderId).push(d);

        return acc;
      }, new Map);
    },

    /**
     * Computes the sections tree structure for the UI.
     *
     * @returns {Array<Object>} The tree structure with folders and diagrams.
     */
    sectionsTree() {
      const itemMap = {}

      // Map each folder to an object with value, label, and children properties
      this.folders.forEach(item => {
        itemMap[item.id] = {
          value: `folder:${item.id}`,
          label: item.name,
          children: [
            ...(this.diagramsByFolder.get(item.id) || []).map((d) => ({
              value: `diagram:${d.id}`,
              label: d.title,
            }))
          ],
        }
      });

      const tree = []
      // Populate the tree structure by linking child folders to their parent folders
      this.folders.forEach(item => {
        if (item.parent_id) {
          if (itemMap[item.parent_id]) {
            itemMap[item.parent_id].children.unshift(itemMap[item.id])
          }
        } else {
          tree.push(itemMap[item.id])
        }
      })

      // Return the tree structure with a root node
      return [
        ...tree,
        ...(this.diagramsByFolder.get(0) || []).map((d) => ({
          value: `diagram:${d.id}`,
          label: d.title,
        }))
      ]
    },

    /**
     * Computes the selected diagram ID.
     *
     * @returns {number|null} The ID of the selected diagram, or null if no diagram is selected.
     */
    selectedDiagramId() {
      const [type, id] = (this.selected || '').split(':');

      return type === 'diagram' && id ? Number(id) : null;
    },

    /**
     * Fragments list
     * @return {any[]}
     */
    fragments() {
      if (!this.selectedDiagramId) {
        return [];
      }

      const diagram = this.diagrams.find((d) => d.id === this.selectedDiagramId);

      const fragments = (diagram?.source?.children || []).filter((fragment) => fragment.type === 'Fragment');

      if (!fragments.length) {
        return [];
      }

      return fragments.map((fragment) => ({
        fragment_id: fragment.id,
        diagramId: diagram.id,
        section: diagram.title,
        folderId: diagram?.folder_id || 0,
        name: fragment?.properties?.title || fragment.title,
        source: fragment,
        deleted: 0,
      }));
    },
  },

  methods: {
    /**
     * Paste UI template
     * @param fragment
     */
    pasteBlock(fragment) {
      this.$emit('paste-ui-template', fragment.source);
    },

    /**
     * Loads the data for folders and diagrams.
     * Fetches folders and diagrams from the database and filters the diagrams
     * to exclude those with titles containing '[template]' or without any fragments.
     */
    async loadData() {
      this.folders = await Folder.query().where({module_id: this.templatesProjectId, type: 'diagram'}).get() || [];

      this.diagrams = (await Diagram.query().where({module_id: this.templatesProjectId}).get() || [])
        .filter((diagram) => {
          if ((diagram?.title || '').includes('[template]')) {
            return false;
          }

          if (!(diagram?.source?.children || []).some((f) => f?.type === 'Fragment')) {
            return false;
          }

          return true;
        })
    },
  },

  async created() {
    if (!this.templatesProjectId) {
      return;
    }

    // Subscribe to UI templates
    await Diagram.remote().subscribe('app-module-diagrams', {module_id: this.templatesProjectId});

    // Subscribe to UI templates folders
    await Folder.remote().subscribe('module-folders', {module_id: this.templatesProjectId});

    // Load data
    await this.loadData();
  },

  beforeUnmount() {
    if (this.templatesProjectId) {
      // Unsubscribe from UI templates
      Diagram.remote().unsubscribe('app-module-diagrams', {module_id: this.templatesProjectId});

      // Unsubscribe from UI templates folders
      Folder.remote().unsubscribe('module-folders', {module_id: this.templatesProjectId});
    }
  },
}
</script>

<style scoped lang="scss">
.ui-template-preview {
  &:deep(.fragment-editor-cmp) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;

    // Firefox not working: https://caniuse.com/css-zoom
    zoom: 0.7;
  }
}
</style>
