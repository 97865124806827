<template>
  <div class="q-pa-md">
    <template v-for="field in schema" :key="field.id">
      <tree-storage-field
        :field="field"
        :value="modelValue"
        @update="updateValue"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TreeStorageContent',

  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Array,
      required: true
    }
  },

  methods: {
    updateValue(val) {
      this.$emit('update:modelValue', val)
    }
  }
}
</script>
