<template>

  <div>

    <ui-prop-string name="title" title="Selection title" :block="block"/>
    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbOptions"/>

    <ui-prop-select v-if="block?.properties?.dbId && !isTreeStorageSelected" :block="block" name="tableId" title="Table" :options="tablesList"/>

    <ui-prop-checkbox v-if="block?.properties?.dbId" :block="block" title="Query single row" name="singleRow"/>

    <ui-prop-checkbox v-if="block?.properties?.dbId && !isTreeStorageSelected" :block="block" title="Raw result" name="rawResult"/>

    <q-separator/>

    <ui-prop-sql-editor v-if="block?.properties?.dbId && !isTreeStorageSelected" :block="block" name="query" title="DB Query" />

    <ui-prop-json-path-editor
      v-if="block?.properties?.dbId && isTreeStorageSelected"
      :block="block"
      name="jsonPath"
      title="JSON path"
      :parent-id="block?.properties?.dbId"
    />

    <ui-prop-value-selector
      v-if="block?.properties?.dbId"
      :only-var-type="isTreeStorageSelected ? 'tree-storage' : 'object,db-record'"
      :block="block"
      title="Variable to save result"
      name="variableToSave"
      value-type="setter"
      :parent-diagram-id="diagram_id"
    />

    <ui-prop-custom-list-props v-if="!isTreeStorageSelected" name="mapping" title="Results mapping" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Property name"/>
        <div class="row">
          <q-select class="col" v-model="data.item.type" :options="globals.options.data_types" label="Property type" map-options emit-value/>
        </div>
        <value-selector v-model="data.item.value" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import UiPropSqlEditor from '@/components/DiagramDesigner/Editor/properties/UiPropSqlEditor.vue';
import {dbListMixin} from '../dbListMixin';
import UiPropJsonPathEditor from '@/components/DiagramDesigner/Editor/properties/UiPropJsonPathEditor.vue';

export default {
  mixins: [propertiesMixins, dbListMixin],
  components: {
    UiPropJsonPathEditor,
    UiPropCustomListProps,
    ValueSelector,
    UiPropCheckbox,
    UiPropValueSelector,
    UiPropSelect,
    UiPropString,
    UiPropSqlEditor,
  },
  props: ['block'],
  name: "DbQueryPropsCmp",
  inject: ["currentModule"],

  data: () => ({
  }),

  computed: {}
}

</script>
