<template>

  <div>
    <ui-prop-string name="title" title="Title" :block="block"/>

    <ui-prop-value-selector :block="block" name="image" title="Image" only-var-type="image" :parent-diagram-id="diagram_id"/>

    <ui-prop-checkbox v-if="!isServerApp" class="q-mt-sm" title="Synchronous response return" name="syncResponse" :block="block"/>

    <ui-prop-dynamic-string
      :multiple="true"
      :block="block"
      title="Instruction"
      name="instruction"
      untranslatable
      :allow-outgoing-bindings="syncResponse"
    />

    <template v-if="!syncResponse">
      <ui-prop-value-selector
        :block="block"
        title="Variable to save job ID"
        name="varToSaveJobId"
        value-type="setter"
        :parent-diagram-id="diagram_id"
      />

      <div class="q-pa-sm q-mt-md rounded-borders bg-grey-2">
        <div>WebSocket channel: <a class="text-bold cursor-pointer text-primary" @click="copyToClipboard('tools:ai:image-explainer')">tools:ai:image-explainer</a></div>

        <div class="q-mt-md">The data structure received in the socket connection is as follows:</div>
        <pre>{{ instructionVarsJson }}</pre>
      </div>
    </template>
  </div>

</template>

<script>
import { copyToClipboard } from 'quasar';
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  components: {UiPropDynamicString, UiPropValueSelector, UiPropCheckbox, UiPropString},
  mixins: [propertiesMixins],
  props: ['block'],
  name: "ImageExplainerPropsCmp",

  methods: {
    /**
     * Copies the specified value to the clipboard.
     * This method attempts to copy the provided value to the user's clipboard using the `copyToClipboard` function.
     * If the operation is successful, a notification with a positive message is displayed to the user.
     * In case of an error (e.g., the browser does not support clipboard operations), the error is logged to the console.
     *
     * @param {string} val - The value to be copied to the clipboard.
     */
    copyToClipboard(val) {
      try {
        copyToClipboard(val);

        this.$q.notify({
          message: 'Copied to clipboard',
          color: 'positive',
        })
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    // Check if the current module is a server app
    isServerApp() {
      return this.currentModule?.currentModule?.type === 'server';
    },

    // Get the title property from the block
    syncResponse() {
      return this.block?.properties?.syncResponse === 1 || this.isServerApp;
    },

    // Get the instruction property from the block
    instruction() {
      return this.block?.properties?.instruction || '';
    },

    // Generate a JSON representation of the instruction variables
    instructionVarsJson() {
      return JSON.stringify({
        jobId: 'job ID',
        ...Object.fromEntries(Object.keys(this.instruction?.outgoingBindings || {}).map((v) => [v, 'value']))
      }, null, 2)
    },
  },
}

</script>
