import DefaultTemplate from "./templates/DefaultTemplate";
import LoginPage from "./pages/auth/LoginPage";
import WorkspaceTemplate from "./pages/workspace/templates/WorkspaceTemplate";
import HomePage from "./pages/HomePage";
import SignupPage from "./pages/auth/SignupPage";
import AppsList from "@/pages/workspace/apps/AppsList";
import ModuleTemplate from "@/pages/workspace/templates/ModuleTemplate.vue";
import ComponentsList from "@/pages/workspace/diagrams/components/ComponentsList";
import ComponentDiagram from "@/pages/workspace/diagrams/components/ComponentDiagram";
import FunctionsList from "@/pages/workspace/diagrams/code/FunctionsList.vue";
import DbsList from "@/pages/workspace/dbs/DbsList.vue";
import ModulesList from "@/pages/workspace/modules/ModulesList.vue";
import StorageTreePage from "@/pages/workspace/storage/StorageTreePage.vue";
import AdmobIntegrationSettings from "@/pages/workspace/integrations/admob/AdmobIntegrationSettings.vue";
import FirebaseIntegrationSettings from "@/pages/workspace/integrations/firebase/FirebaseIntegrationSettings.vue";
import SingularIntegrationSettings from "@/pages/workspace/integrations/singular/SingularIntegrationSettings.vue";
import UsersList from "@/pages/workspace/users/UsersList.vue";
import DbTablesList from "@/pages/workspace/dbs/DbTablesList.vue";
//import AiWizard from "@/pages/workspace/wizard/AiWizard.vue";
import DbTableContent from "@/pages/workspace/dbs/DbTableContent.vue";
import InAppPurchaseIntegrationSettings from "@/pages/workspace/integrations/iap/InAppPurchaseIntegrationSettings.vue";
import ModuleDetails from "@/pages/workspace/modules/ModuleDetails.vue";
import StylesManager from "@/pages/workspace/modules/styles/StylesManager.vue";
import GitIntegrationSettings from "@/pages/workspace/integrations/git/GitIntegrationSettings.vue";
import BigQueryIntegrationSettings from "@/pages/workspace/integrations/bigquery/BigQueryIntegrationSettings.vue";
import AndroidIntegrationSettings from "@/pages/workspace/integrations/os/android/AndroidIntegrationSettings.vue";
import ReleaseList from "@/pages/workspace/releases/ReleaseList.vue";
import AnalyticsPage from "@/pages/workspace/analytics/AnalyticsPage.vue";
import LayoutIntegrationSettings from "@/pages/workspace/integrations/layout/LayoutIntegrationSettings.vue";
import AnalyticsRetention from "@/pages/workspace/analytics/AnalyticsRetention.vue";
import AnalyticsFunnels from "@/pages/workspace/analytics/funnels/AnalyticsFunnels.vue";
import AnalyticsKpis from "@/pages/workspace/analytics/AnalyticsKpis.vue";
import LoaderIntegrationSettings from "@/pages/workspace/integrations/loader/LoaderIntegrationSettings.vue";
import FacebookIntegrationSettings from '@/pages/workspace/integrations/facebook/FacebookIntegrationSettings.vue';
import IosIntegrationSettings from "@/pages/workspace/integrations/os/ios/IosIntegrationSettings.vue";
import ModuleInfo from "@/pages/workspace/modules/ModuleInfo.vue";
import LocalizationsIntegrationSettings
    from '@/pages/workspace/integrations/localizations/LocalizationsIntegrationSettings.vue';
import WebIntegrationSettings from '@/pages/workspace/integrations/os/web/WebIntegrationSettings.vue';
import AnalyticsAudience from '@/pages/workspace/analytics/AnalyticsAudience.vue';
import ShopifyIntegrationSettings from '@/pages/workspace/integrations/shopify/ShopifyIntegrationSettings.vue';
import AdaptyIntegrationSettings from '@/pages/workspace/integrations/adapty/AdaptyIntegrationSettings.vue';
import MediaGallery from '@/pages/workspace/media-gallery/MediaGallery.vue';
import AuthIntegrationSettings from '@/pages/workspace/integrations/auth/AuthIntegrationSettings.vue';
import SendGridIntegrationSettings from '@/pages/workspace/integrations/sendgrid/SendGridIntegrationSettings.vue';
import StripeIntegrationSettings from '@/pages/workspace/integrations/stripe/StripeIntegrationSettings.vue';
import InAppPurchaseSyncIntegrationSettings
    from '@/pages/workspace/integrations/iap/InAppPurchaseSyncIntegrationSettings.vue';
import StripeWebIntegrationSettings from '@/pages/workspace/integrations/stripe/StripeWebIntegrationSettings.vue';
import MarketingPixelsIntegrationSettings
    from '@/pages/workspace/integrations/marketing-pixels/MarketingPixelsIntegrationSettings.vue';
import HealthCheck from '@/pages/workspace/health-check/HealthCheck.vue';
import ConstantsPage from "@/pages/workspace/storage/ConstantsPage.vue";
import ServicesTemplate from '@/pages/workspace/templates/ServicesTemplate.vue';
import IosPermissionsIntegrationSettings
    from '@/pages/workspace/integrations/os/ios-permissions/IosPermissionsIntegrationSettings.vue';
import TreeStoragePage from '@/pages/workspace/storage/TreeStoragePage.vue';
import P3PDiagramEditor from "@/pages/p3p/diagram/P3PDiagramEditor.vue";
import WebBuilder from "@/pages/p3p/builders/WebBuilder.vue";
import P3PTemplate from "@/pages/p3p/P3PTemplate.vue";

export const routes = [
    {
        path: '/p3p',
        component: P3PTemplate,
        children: [
            {
                path: 'diagram-editor/:token',
                component: P3PDiagramEditor,
            },
            {
                path: 'web-builder/:token',
                component: WebBuilder,
            }
        ]
    },
    {
        path: '/',
        component: DefaultTemplate,
        children: [
            {
                name: 'home',
                path: '/',
                component: HomePage
            },
            {
                name: 'login',
                path: '/login',
                component: LoginPage
            },
            {
                meta: {
                    auth: {
                        roles: ["user"]
                    }
                },
                name: 'signup',
                path: '/signup',
                component: SignupPage
            },
        ]
    },

    // Workspace
    {
        path: '/workspace',
        meta: {
            auth: {
                roles: ["user"],
                redirect: "login"
            }
        },
        children: [
            {
                path: '/workspace',
                component: WorkspaceTemplate,
                children: [
                    {
                        name: 'admin-users',
                        path: '/workspace/admin-users',
                        component: UsersList
                    },
                    {
                        name: 'workspace',
                        path: '/workspace',
                        component: AppsList
                    },
                    /*{
                        name: 'wizard',
                        path: '/wizard',
                        component: AiWizard
                    },*/
                    {
                        name: 'app-modules',
                        path: '/workspace/:app_id',
                        component: ModulesList,
                    },

                ]
            },
            {
                name: 'module-designer',
                path: '/workspace/:app_id',
                component: ModuleTemplate,
                children: [
                    {
                        name: 'module-details',
                        path: '/workspace/:app_id/:module_id',
                        component: ModuleDetails
                    },

                    {
                        name: 'module-info',
                        path: '/workspace/:app_id/:module_id/info',
                        component: ModuleInfo
                    },

                    /*{
                        name: 'app',
                        path: '/workspace/:app_id/:module_id',
                        component: AppDetails
                    },*/
                    {
                        name: 'components',
                        path: '/workspace/:app_id/:module_id/components/list/:folder_id?',
                        component: ComponentsList
                    },
                    /*{
                        name: 'marketing-keywords',
                        path: '/workspace/:app_id/:module_id/marketing/keywords/:country?/:language?',
                        component: MarketingKeywordList
                    },*/
                    {
                        name: 'analytics-paths',
                        path: '/workspace/:app_id/:module_id/analytics-paths/:id?/:component_id?',
                        component: AnalyticsPage
                    },
                    {
                        name: 'analytics-kpi',
                        path: '/workspace/:app_id/:module_id/analytics-kpi',
                        component: AnalyticsKpis
                    },
                    {
                        name: 'analytics-retention',
                        path: '/workspace/:app_id/:module_id/analytics-retention/:id?',
                        component: AnalyticsRetention
                    },
                    {
                        name: 'analytics-audience',
                        path: '/workspace/:app_id/:module_id/analytics-audience/:id?',
                        component: AnalyticsAudience
                    },
                    {
                        name: 'analytics-funnels',
                        path: '/workspace/:app_id/:module_id/analytics-funnels/:id?',
                        component: AnalyticsFunnels
                    },
                    /*{
                        name: 'functions',
                        path: '/workspace/:app_id/:module_id/functions/list',
                        component: FuncList
                    },*/
                    {
                        name: 'functions',
                        path: '/workspace/:app_id/:module_id/functions',
                        component: FunctionsList
                    },
                    {
                        name: 'storage',
                        path: '/workspace/:app_id/:module_id/storage',
                        component: StorageTreePage
                    },
                    {
                        name: 'constants',
                        path: '/workspace/:app_id/:module_id/constants',
                        component: ConstantsPage
                    },
                    {
                        name: 'tree-storage',
                        path: '/workspace/:app_id/:module_id/tree-storage',
                        component: TreeStoragePage,
                    },
                    {
                        name: 'styles',
                        path: '/workspace/:app_id/:module_id/styles',
                        component: StylesManager
                    },
                    {
                        name: 'releases',
                        path: '/workspace/:app_id/:module_id/releases',
                        component: ReleaseList
                    },
                    {
                        name: 'dbs',
                        path: '/workspace/:app_id/:module_id/dbs',
                        component: DbsList
                    },
                    {
                        name: 'db-tables',
                        path: '/workspace/:app_id/:module_id/db-tables/:db_id',
                        component: DbTablesList
                    },
                    {
                        name: 'db-table-content',
                        path: '/workspace/:app_id/:module_id/db-tables/:db_id/:table_id/data/:test?',
                        component: DbTableContent
                    },
                    {
                        name: 'integrations-admob',
                        path: '/workspace/:app_id/:module_id/settings/admob',
                        component: AdmobIntegrationSettings
                    },
                    {
                        name: 'integrations-loader',
                        path: '/workspace/:app_id/:module_id/settings/loader',
                        component: LoaderIntegrationSettings
                    },
                    {
                        name: 'integrations-layout',
                        path: '/workspace/:app_id/:module_id/settings/layout',
                        component: LayoutIntegrationSettings
                    },
                    {
                        name: 'integrations-git',
                        path: '/workspace/:app_id/:module_id/settings/git',
                        component: GitIntegrationSettings
                    },
                    {
                        name: 'integrations-bq',
                        path: '/workspace/:app_id/:module_id/settings/bq',
                        component: BigQueryIntegrationSettings
                    },
                    {
                        name: 'integrations-os-android',
                        path: '/workspace/:app_id/:module_id/settings/os/android',
                        component: AndroidIntegrationSettings
                    },
                    {
                        name: 'integrations-os-ios',
                        path: '/workspace/:app_id/:module_id/settings/os/ios',
                        component: IosIntegrationSettings
                    },
                    {
                        name: 'integrations-os-ios-permissions',
                        path: '/workspace/:app_id/:module_id/settings/os/ios-permissions',
                        component: IosPermissionsIntegrationSettings,
                    },
                    {
                        name: 'integrations-os-web',
                        path: '/workspace/:app_id/:module_id/settings/os/web',
                        component: WebIntegrationSettings
                    },
                    {
                        name: 'integrations-iap',
                        path: '/workspace/:app_id/:module_id/settings/iap',
                        component: InAppPurchaseIntegrationSettings
                    },
                    {
                        name: 'integrations-iap-sync',
                        path: '/workspace/:app_id/:module_id/settings/iap-sync',
                        component: InAppPurchaseSyncIntegrationSettings,
                    },
                    {
                        name: 'stripe',
                        path: '/workspace/:app_id/:module_id/settings/stripe',
                        component: StripeIntegrationSettings,
                    },
                    {
                        name: 'stripe-web',
                        path: '/workspace/:app_id/:module_id/settings/stripe-web',
                        component: StripeWebIntegrationSettings,
                    },
                    {
                        name: 'integrations-firebase',
                        path: '/workspace/:app_id/:module_id/settings/firebase',
                        component: FirebaseIntegrationSettings
                    },
                    {
                        name: 'integrations-singular',
                        path: '/workspace/:app_id/:module_id/settings/singular',
                        component: SingularIntegrationSettings
                    },
                    {
                        name: 'component-diagram',
                        path: '/workspace/:app_id/:module_id/components/:id',
                        component: ComponentDiagram
                    },
                    {
                        name: 'integrations-facebook',
                        path: '/workspace/:app_id/:module_id/settings/facebook',
                        component: FacebookIntegrationSettings
                    },
                    {
                        name: 'localizations',
                        path: '/workspace/:app_id/:module_id/settings/localizations',
                        component: LocalizationsIntegrationSettings,
                    },
                    {
                        name: 'shopify',
                        path: '/workspace/:app_id/:module_id/settings/shopify',
                        component: ShopifyIntegrationSettings,
                    },
                    {
                        name: 'integrations-adapty',
                        path: '/workspace/:app_id/:module_id/settings/adapty',
                        component: AdaptyIntegrationSettings,
                    },
                    {
                        name: 'auth',
                        path: '/workspace/:app_id/:module_id/settings/auth',
                        component: AuthIntegrationSettings,
                    },
                    {
                        name: 'sendgrid',
                        path: '/workspace/:app_id/:module_id/settings/sendgrid',
                        component: SendGridIntegrationSettings,
                    },
                    {
                        name: 'marketing-pixels',
                        path: '/workspace/:app_id/:module_id/settings/marketing-pixels',
                        component: MarketingPixelsIntegrationSettings,
                    },
                    {
                        name: 'media-gallery',
                        path: '/workspace/:app_id/:module_id/media-gallery',
                        component: MediaGallery,
                    },
                    {
                        name: 'health-check',
                        path: '/workspace/:app_id/:module_id/health-check',
                        component: HealthCheck,
                    },
                ]
            },
            {
                path: '/workspace/services',
                name: 'services',
                component: ServicesTemplate,
                children: [
                    {
                        path: '/workspace/services/health-check/:service',
                        name: 'services-health-check',
                        component: HealthCheck,
                    }
                ],
            }
        ]
    }
]
