<template>

  <q-card class="full-width">
    <q-card-section>
      <div class="text-h6">Edit table index</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form @submit="onSaveDbIndex">

        <q-input
            v-model.trim="mutate(editIndex).name"
            label="Index name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-select
          v-model="mutate(editIndex).type"
          label="Type *"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
          map-options
          emit-value
          :options="indexTypes"
        />

        <q-select
          v-model="mutate(editIndex).fields"
          label="Fields *"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
          map-options
          emit-value
          :options="fields"
          multiple
          use-chips
        />

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>
import {DbTableIndex} from "@/../../common/db/DbTableIndex";
import {DbTableField} from '../../../../../common/db/DbTableField';

export default {
  name: "TableIndexForm",
  props: ['editIndex'],
  emits: ['saved'],

  data() {
    return {
      indexTypes: [
        {value: 'index', label: 'Index'},
        {value: 'unique', label: 'Unique'},
      ],
    };
  },

  computed: {
    // Return all fields from the store
    fields() {
      return this.wait("table-fields", async () => {
        const fields = await DbTableField.query().where({table_id: this.editIndex.table_id}).get() || [];

        return fields.map(field => {
          return {
            label: field.name,
            value: field.name
          }
        });
      }, [])
    }
  },

  methods: {
    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveDbIndex() {
      // Update
      await DbTableIndex.remote().save(this.editIndex)

      // Hide dialog
      this.$emit('saved')
    }
  },
}

</script>
