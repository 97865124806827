<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="diagram-component-editor-cmp">

    <q-card :class="{'embedded': isEmbedded}">

      <q-card-section class="bg-secondary text-white">
        {{ block?.properties?.title }}
      </q-card-section>
      <q-card-section v-if="!isExternalSource">
        <router-link v-if="componentId" @click.stop :to="{params: {id: getIds(componentId)}}">{{ name }}</router-link>
        <template v-else>Not set</template>
      </q-card-section>
      <q-card-section v-else>
        {{extDiagramName}}
      </q-card-section>

    </q-card>

    <div class="outgoing">
      <ab-flow-link-connector v-for="(event) of outEvents" :block="block" type="output" :key="event"
                              :event="event"/>
    </div>

    <div class="incoming">
      <ab-flow-link-connector :block="block" type="input" event="start"/>
      <ab-flow-link-connector v-for="(event) of inEvents" :block="block" type="input" :key="event"
                              :event="event"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {P3PNodeService} from "@/pages/p3p/utils/P3PNodeService";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: ['canvas', 'designer'],
  props: ['block'],
  name: "DiagramComponentEditorCmp",
  computed: {

    /**
     * Is embedded
     * @return boolean
     */
    isEmbedded() {
      return this.block.properties?.isEmbedded
    },

    /**
     * Load component
     * @return {*}
     */
    diagramComponent() {

      if(!this.isExternalSource) {

        // No component name
        if (!this.block.properties?.diagramComponentId) return {title: "Noname", id: null}

        // Load component params
        return this.wait("diagramComponent", async () => {
          return this.designer.getDiagram(this.block.properties.diagramComponentId)
        }, {})
      } else if(this.block.properties?.diagramComponentId?.accessToken) {
        return this.wait('diagramComponentExternal', async () => {
          const dgNode = await new P3PNodeService(this.block.properties?.diagramComponentId?.accessToken).init();
          //console.log("!S", dgNode?.properties?.schema);
          return {
            title: this.block.properties?.diagramComponentId?.title,
            source: dgNode?.properties?.schema,
          }
        }, {})
      }

      // Nothing
      return false;
    },

    /**
     * Return name
     * @return {{}}
     */
    name() {
      // Validate
      return this.diagramComponent.title || this.diagramComponent.alias || 'Unknown';
    },

    /**
     * External diagram name
     * @return {*|string}
     */
    extDiagramName() {
        return this.block.properties?.diagramComponentId?.title || 'Unknown';
    },

    /**
     * Return component id
     * @return {{}}
     */
    componentId() {
      // Validate
      return this.diagramComponent.id
    },

    /**
     * Outgoing events list
     * @return {*|*[]}
     */
    outEvents() {
      // Get diagram children list
      const topChildren = this.diagramComponent.source?.children

      // Find events list
      return [...['default'], ...(topChildren?.filter(c => c.type === 'CustomEvent' && c.properties?.eventType === 'outgoing').map(c => (c.properties?.name)) || [])]
    },

    /**
     * Incoming events list
     * @return {*|*[]}
     */
    inEvents() {
      // Get diagram children list
      const topChildren = this.diagramComponent.source?.children

      // Find events list
      if (topChildren) return topChildren.filter(
        c => c.type === 'CustomEvent' && c.properties?.eventType === 'incoming' && c.properties?.name !== 'start'
      ).map(c => (c.properties?.name))

      // No events
      return []
    }
  },

  methods: {

    /**
     * Get ids list for route
     * @param id
     * @returns {string}
     */
    getIds(id) {
      return [...this.$route.params.id?.split(','), ...[id]].join(",")
    },
  }
}

</script>

<style lang="scss">

.diagram-component-editor-cmp {
  .ev-run {
    left: -12px;
    position: absolute;
    top: calc(50% - 5px);
  }

  .outgoing {
    z-index: 1;
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .incoming {
    z-index: 1;
    position: absolute;
    left: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
