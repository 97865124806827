<template>

  <ab-flow-base-cmp class="navigation-panel row" :block="block" :class="cmpClasses" :style="stylesString">
    <q-tabs
      v-model="tab"
      class="col relative-position"
      no-caps
      :switch-indicator="selPosition"
      :vertical="vertical"
      :inline-label="vertical"
      :outside-arrows="false"
      :mobile-arrows="false"
      :style="{
        '--tabs-justify-content': tabsAlign
      }"
    >
      <template v-if="!vertical">
        <q-resize-observer @resize="onResizeContainer" />

        <div class="measure-container">
          <div ref="measureContainer" class="measure-container__tabs">
            <q-tab
              v-for="item in itemsList"
              :key="item.value"
              :name="item.value"
            ><label>{{ item.title }}</label></q-tab>
          </div>
        </div>
      </template>

      <template v-for="(item, i) of visibleTabs" :key="item.title">
        <q-tab :icon="getIcon(item)" :name="item.value">
          <label>{{item.title}}</label>
          <ab-flow-link-connector class="link-connector" :key="i" :block="block" type="output" :event="item.value"/>
        </q-tab>
      </template>

      <q-btn-dropdown
        v-if="hiddenTabs.length"
        class="navigation-panel-dropdown"
        auto-close
        stretch
        flat
        icon="menu"
        persistent

      >
        <q-list :class="cmpClasses">
          <template v-for="item of hiddenTabs" :key="item.value">
            <q-item clickable>
              <q-item-section v-if="getIcon(item)" avatar style="min-width: 0;">
                <q-icon :name="getIcon(item)" />
              </q-item-section>

              <q-item-section>{{ item.title }}</q-item-section>
            </q-item>
          </template>
        </q-list>
      </q-btn-dropdown>
    </q-tabs>

    <div v-if="hiddenTabs" class="hidden-events">
      <ab-flow-link-connector
        v-for="(item, i) of hiddenTabs"
        :key="i"
        class="link-connector"
        :block="block"
        type="output"
        :event="item.value"
      />
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {pathHelper} from '@/utils/pathHelper';

const DROPDOWN_BTN_WIDTH = 56;

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  mixins: [renderMixins],
  props: ['block'],
  name: "NavigationPanelEditorCmp",
  data: () => ({
    tab: "",
    tabWidths: {},
    tabsContainerWidth: 0,
  }),

  computed: {

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * If up position
     * @return {boolean}
     */
    selPosition() {
      return ['right', 'down'].includes(this.block?.properties?.position)
    },

    /**
     * Items list
     * @return {*}
     */
    itemsList() {

      // get items from node
      const dynItems = (this.getValue(this.block?.properties?.dynamicItems) || [])?.filter(item => item && typeof item === 'object')?.map(item => ({
          title: item.title || item.label || item.name || "No Name",
          value: item.value || item.id || "No value",
        }));

      // return items
      return [...(this.block?.properties?.items?.items || []).map((item) => ({
        ...item,
        title: this.interpretString(item.title),
      })), ...dynItems]
    },


    /**
     * Button color
     * @return {string}
     */
    buttonColor() {
      return this.block?.properties?.color || "";
    },

    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * Computes the classes to be applied to the component.
     * It combines the classes from the getClasses method and additional classes
     * based on the disableJustifyAlignment and tabsAlign properties.
     *
     * @returns {Array} An array of class names.
     */
    cmpClasses() {
      return [
        this.getClasses(false, {contentAlign: true}),
        {
          'tabs-disable-justify-align': this.disableJustifyAlignment,
          'tabs-justify-content': !!this.tabsAlign,
        }
      ];
    },

    /**
     * Determines if the justify alignment should be disabled.
     * This is based on the `disableJustifyAlignment` property of the block.
     *
     * @returns {boolean} True if justify alignment is disabled, otherwise false.
     */
    disableJustifyAlignment() {
      return this.block?.properties?.disableJustifyAlignment === 1 && !this.vertical;
    },

    /**
     * Computes the alignment for the tabs.
     * If the tabs are vertical or justify alignment is not disabled, returns null.
     * Otherwise, returns the content alignment from the block properties.
     *
     * @returns {string|null} The alignment for the tabs or null.
     */
    tabsAlign() {
      if (this.vertical || !this.disableJustifyAlignment) {
        return null;
      }

      return this.block?.properties?.contentAlign || null;
    },

    /**
     * Computes the list of visible tabs based on the available width.
     * If the tabs are displayed vertically, all tabs are considered visible.
     * Otherwise, it calculates the visible tabs based on their widths and the available width.
     *
     * @returns {Array} The list of visible tabs.
     */
    visibleTabs() {
      if (this.vertical) {
        return this.itemsList;
      }

      // Calculate the total width of all tabs.
      const totalTabsWidth = Object.values(this.tabWidths).reduce((acc, width) => acc + width, 0);

      // If the total width of all tabs is less than the width of the container, all tabs are visible.
      if (this.tabsContainerWidth >= totalTabsWidth) {
        return this.itemsList;
      }

      let availableWidth = this.tabsContainerWidth - DROPDOWN_BTN_WIDTH;
      let visibleTabs = [];
      let currentWidth = 0;

      for (const tab of this.itemsList) {
        const tabWidth = this.tabWidths[tab.value] || 0;
        if (currentWidth + tabWidth <= availableWidth) {
          visibleTabs.push(tab);
          currentWidth += tabWidth;
        } else {
          break;
        }
      }

      return visibleTabs;
    },

    /**
     * Computes the list of hidden tabs.
     * The hidden tabs are those that are not visible due to the available width.
     *
     * @returns {Array} The list of hidden tabs.
     */
    hiddenTabs() {
      return this.itemsList.slice(this.visibleTabs.length);
    },
  },

  methods: {
    /**
     * Get icon
     * @param item
     * @return {string|undefined}
     */
    getIcon(item) {
      // If icon mode return icon
      if (this.viewMode === 'icon') {
        return typeof item.icon === 'string' ? item.icon : undefined;
      }

      // Get active image
      const activeImage = item.activeImage?.source_url || undefined;

      // If active tab and active image
      if (item.value === this.tab && activeImage) {
        return `img:${pathHelper.assetPath(activeImage)}`;
      }

      // Get image
      const image = item.image?.source_url || undefined;

      // Return image
      return image ? `img:${pathHelper.assetPath(image)}` : undefined;
    },

    /**
     * Measures the widths of the tabs and stores them in the `tabWidths` object.
     * This method waits for the next DOM update cycle before measuring the widths.
     * It uses the `measureContainer` reference to find all tab elements and calculates their widths.
     * The widths are then stored in the `tabWidths` object with the tab value as the key.
     */
    async measureTabWidths() {
      await this.$nextTick();

      const container = this.$refs?.measureContainer;

      if (!container) return

      const tabs = container.querySelectorAll('.q-tab')
      const widths = {}

      tabs.forEach((tab, idx) => {
        widths[this.itemsList[idx].value] = tab.getBoundingClientRect().width
      })

      this.tabWidths = widths
    },

    /**
     * Handles the resize event of the container.
     * Updates the `tabsContainerWidth` with the new width of the container.
     *
     * @param {Object} param - The resize event parameter.
     * @param {number} param.width - The new width of the container.
     */
    onResizeContainer({width}) {
      this.tabsContainerWidth = width;
    },
  },

  watch: {
    itemsList: {
      handler() {
        this.measureTabWidths()
      },
      immediate: true,
    },
  },
}

</script>

<style lang="scss">
.navigation-panel {
  position: relative;

  .q-tab {
    padding: 0 10px;
  }

  .q-tabs--vertical {
    .q-tab {
      justify-content: start;

      label {
        margin-left:5px;
      }

    }
  }

  &.tabs-disable-justify-align {
    .q-tab {
      flex: 0 !important;
    }
  }

  &.tabs-justify-content {
    .q-tabs__content {
      justify-content: var(--tabs-justify-content);
    }
  }

  .link-connector {
    z-index: 1;
    position: absolute;
    bottom: 5%;
  }

  .measure-container {
    width: 0;
    height: 0;
    overflow: hidden;

    &, &__tabs {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  .navigation-panel-dropdown {
    padding-top: 10px;

    .q-btn-dropdown__arrow {
      display: none !important;
    }
  }

  .hidden-events {
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    position: absolute;
    top: 2px;
    left: 50%;
    transform: translateX(-50%);

    .link-connector {
      position: static;
    }
  }
}
</style>
