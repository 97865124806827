<template>

  <modal-dialog ref="actionExecutor" title="AI Action executor" full-height>
    <a-i-action-executor :block="block"/>
  </modal-dialog>

  <div>
    <ui-prop-string name="title" title="Action title" :block="block"/>
    <ui-prop-dynamic-string :multiple="true" name="description" title="Action description" :block="block">
      <template #actions>
        <q-btn @click="executeAction" flat round size="sm" icon="play_arrow"/>
      </template>
    </ui-prop-dynamic-string>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import ModalDialog from "@/components/ModalDialog/ModalDialog.vue";
import AIActionExecutor from "@/components/DiagramDesigner/Editor/components/UI/Logic/AIFunction/AIActionExecutor.vue";

export default {
  mixins: [propertiesMixins],
  components: {AIActionExecutor, ModalDialog, UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "AIFunctionPropsCmp",
  data: () => ({
    json: "",
    tab: "function",
    defaultResult: {error: {}, result: {}, references: {}}
  }),
  created() {
    if(!this.block?.properties) this.mutate(this.block).properties = {}
  },
  methods: {
    executeAction() {
      this.$refs.actionExecutor.show();
    }
  }
}

</script>
