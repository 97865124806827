<template>

  <div class="q-gutter-y-sm">
    <div>Web build list</div>

    <q-btn color="primary" @click="buildApp">Build</q-btn>
  </div>
</template>

<script>

import {p3pMixins} from "@/pages/p3p/p3pMixins";

export default {
  name: "WebBuilderBuilds",
  mixins: [p3pMixins],
  methods: {
    /**
     * Build application
     */
    buildApp() {
      this.app.client.call("p3p", "webBuild", this.p3pNode.token)
    }
  }
}
</script>
