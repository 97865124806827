<template>
  <q-page class="component-list">

    <modal-dialog ref="editComponent" title="New component" persistent>
      <component-form :edit-component="editComponent" :product="product" @closed="$refs.editComponent.hide()"/>
    </modal-dialog>

    <q-dialog ref="uiTemplatesList" class="full-width full-height">
      <q-card class="full-width full-height column">
        <q-card-section class="bg-primary text-white row">
          <div class="text-subtitle1">UI Templates</div>
          <q-space/>
          <q-btn icon="close" size="sm" round flat @click="$refs.uiTemplatesList.hide()"/>
        </q-card-section>
        <q-card-section class="col">
          <ui-templates-diagrams-list
            @paste-ui-template="onPasteDiagramTemplate"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <modal-dialog ref="moveComponent" title="Move component" persistent>
      <folder-picker
        type="diagram"
        :app_id="app_id"
        :module_id="module_id"
        v-model="moveFolderId"
      />

      <div class="q-mt-md">
        <q-btn
          label="Move"
          color="primary"
          :disable="typeof moveFolderId !== 'number' || moveFolderId === folderId"
          @click="onMoveComponent"
        />
      </div>
    </modal-dialog>

    <folders-list type="diagram" :app_id="app_id" :module_id="module_id" />

    <q-separator spaced/>

    <div class="row">
      <div class="col q-ml-sm q-mr-sm">
        <q-input class="full-width" placeholder="Search string" v-model="searchString"/>
      </div>
      <q-space/>
      <q-tabs v-model="tab" align="right">
        <q-tab name="active" label="Active"></q-tab>
        <q-tab name="archived" label="Archived"></q-tab>
      </q-tabs>
    </div>

    <div class="row q-gutter-md q-ma-sm">

      <template v-for="(component, k) of currentComponents" :key="k">

        <q-card class="col-md-3 col-xs-12">
          <q-card-section :class="`component-${component.diagram_type}`">
            <div class="text-subtitle1 row">
              <div>
                <q-btn icon="edit" size="sm" class="q-mr-sm" round flat @click="editComponentDiagram(component)"/>
              </div>
              <div class="col">
                <div class="ellipsis">{{ component.title }}</div>
                <div class="text-subtitle2">{{ component.diagram_type }}</div>
              </div>

              <div>
                <q-btn round size="sm" flat icon="menu">
                  <q-menu>
                    <q-list style="min-width: 100px">

                      <q-item clickable v-close-popup
                              @click="editComponent = component.data();$refs.editComponent.show()">
                        <q-item-section>Edit</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="deleteComponent(component)">
                        <q-item-section>Delete</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="copyComponent(component)">
                        <q-item-section>Copy</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="moveComponent(component)">
                        <q-item-section>Move</q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="openAnalytics(component)">
                        <q-item-section>Open analytics</q-item-section>
                      </q-item>

                    </q-list>
                  </q-menu>
                </q-btn>
              </div>

            </div>
          </q-card-section>

          <q-card-section class="row ellipsis-3-lines q-mb-md" v-html="component.description?.replaceAll?.('\n', '<br>')"/>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action v-if="componentIdToPaste" icon="content_paste" label="Paste component" color="secondary"
                      @click="pasteComponent"/>
        <q-fab-action icon="add" label="Add new" color="accent"
                      @click="editComponent={diagram_type: 'component', status: 'active', app_id: this.app_id, module_id: this.module_id, unique_id: nanoid(10), folder_id: folderId};$refs.editComponent.show()"/>
        <q-fab-action icon="content_copy" label="From template" color="primary" @click="$refs.uiTemplatesList.show()"/>
      </q-fab>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>

//Implement paste diagram

import ComponentForm from "./ComponentForm";
import {Diagram} from "@/../../common/db/Diagram.js"
import {AppModule} from "@/../../common/db/AppModule.js"
import {nanoid} from "nanoid";
import {Localization} from '../../../../../../common/db/Localization';
import {LocalizationMessage} from '../../../../../../common/db/LocalizationMessage';
import TabCommunicationMixin from '@/mixins/TabCommunicationMixin';
import ModalDialog from "@/components/ModalDialog/ModalDialog.vue";
import FoldersList from '@/pages/workspace/folders/FoldersList.vue';
import {Folder} from '../../../../../../common/db/Folder';
import FolderPicker from '@/pages/workspace/folders/FolderPicker.vue';

export default {
  name: 'ComponentsList',
  components: {FolderPicker, FoldersList, ModalDialog, ComponentForm},

  mixins: [TabCommunicationMixin],

  props: ['product'],
  data: () => ({
    searchString: "",
    editComponent: false,
    app_id: false,
    tab: "active",
    module_id: false,
    moveFolderId: null,
    movedComponent: null,
  }),

  /**
   * Created hook
   * @return {Promise<void>}
   */
  async created() {

    // Get app id from the route
    this.app_id = Number(this.$route.params.app_id);
    this.module_id = Number(this.$route.params.module_id);

    // Subscribe to modules
    await Diagram.remote().subscribe("app-module-diagrams", {module_id: this.module_id})

    // Subscribe to localizations
    await Localization.remote().subscribe("module-localizations", {module_id: this.module_id})
    await LocalizationMessage.remote().subscribe("module-localization-messages", {module_id: this.module_id})

    // Subscribe to modules
    await AppModule.remote().subscribe("app-modules", {app_id: this.app_id})

    // Subscribe to folders
    await Folder.remote().subscribe("folders", {
      type: 'diagram',
      app_id: this.app_id,
      module_id: this.module_id,
    });
  },

  computed: {
    /**
     * Computes the folder ID from the route parameters.
     * If no folder ID is provided, defaults to 0.
     *
     * @returns {number} The folder ID.
     */
    folderId() {
      return Number(this.$route.params.folder_id || 0);
    },

    /**
     * Computes the current components based on the selected tab, search string, and folder ID.
     * Filters components by their status, search string, and folder ID.
     *
     * @returns {Array} The filtered list of components.
     */
    currentComponents() {
      return this.components?.filter(
        c => c.status === this.tab && (!this.searchString ||
              (c.title + " " + c.description).toLowerCase().includes(this.searchString.toLowerCase())
          ) && c.folder_id === this.folderId
      )
    },

    /**
     * Return component id from the local storage
     * @returns {string}
     */
    componentIdToPaste() {
      return this.app.client.config.get('copy_component', false)
    },

    /**
     * Return all components from the store
     * @returns {*}
     */
    components() {
      //where("diagram_type", 'component').
      return this.wait("components", Diagram.query().where("module_id", this.module_id).order("diagram_type asc, id asc").get(), [])
    }
  },

  methods: {

    nanoid,

    /**
     * Delete component
     */
    pasteComponent(diagram = null) {
      const isTemplate = (diagram?.title || '').includes('[template]');

      this.$q.dialog({
        title: 'Paste confirmation',
        message: `Are you sure want to paste component?`,
        cancel: true,
        persistent: true,
        ...(isTemplate ? {
          prompt: {
            model: diagram.title.replace('[template]', '').replace('[inline]', '').trim(),
            type: 'text'
          },
        } : {})
      }).onOk(async (newTitle) => {
        try {
          // Duplicate component
          const newComponent = await Diagram.remote().call('app', 'duplicateDiagram', {
            diagram_id: this.componentIdToPaste,
            app_id: this.app_id,
            module_id: this.module_id,
            folder_id: this.folderId,
            newTitle,
          });

          // show toast
          if (newComponent) {
            this.$q.notify({
              message: 'Component pasted',
              color: 'positive',
              icon: 'check',
              position: 'top'
            });

            this.$refs?.uiTemplatesList?.hide();
          }

          // remove component id from the local storage
          this.app.client.config.set('copy_component', false)

          this.sendMessageToTab('paste-diagram');
        } catch (e) {
          this.$q.notify({
            message: e?.error || e?.message || 'Error pasting component',
            color: 'negative',
            icon: 'error',
            position: 'top'
          })
        }
      })
    },

    /**
     * Edit component
     * @param component
     */
    editComponentDiagram(component) {
      this.$router.push({name: 'component-diagram', params: {id: component.id}})
    },

    /**
     * Copy component
     * @param component
     */
    copyComponent(component) {

      // store component id in the local storage
      this.app.client.config.set('copy_component', component.id)

      // show toast
      this.$q.notify({
        message: 'Component copied',
        color: 'positive',
        icon: 'check',
        position: 'top'
      });

      this.sendMessageToTab('copy-diagram', component.id);
    },

    /**
     * Open analytics
     * @param component
     */
    openAnalytics(component) {
      this.$router.push({name: 'analytics-paths', params: {id: component.id}})
    },

    /**
     * Delete component
     * @param component
     * @return {Promise<void>}
     */
    async deleteComponent(component) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${component.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        Diagram.remote().delete(component.id)
      })
    },

    /**
     * Handle messages received from other tabs.
     * @param {Event} e - The event object containing the message data.
     */
    handleTabMessage(e) {
      const {type, content} = e.data;

      switch (type) {
        case 'copy-diagram':
          this.app.client.config.set('copy_component', content);
          break;
        case 'paste-diagram':
          this.app.client.config.set('copy_component', false);
          break;
      }
    },

    /**
     * Handles the paste action for a diagram template.
     * Sets the copied component ID in the client config and triggers the pasteComponent method.
     *
     * @param {Diagram} diagram - The ID of the diagram template to paste.
     */
    onPasteDiagramTemplate(diagram) {
      this.app.client.config.set('copy_component', diagram.id)

      this.pasteComponent(diagram);
    },

    /**
     * Opens the move component dialog and sets the component to be moved.
     *
     * @param {Object} component - The component to be moved.
     */
    async moveComponent(component) {
      this.movedComponent = component;
      this.$refs.moveComponent.show();
    },

    /**
     * Handles the move component action.
     * Displays a confirmation dialog and, upon confirmation, moves the component to the selected folder.
     */
    async onMoveComponent() {
      this.$q.dialog({
        title: 'Move confirmation',
        message: `Are you sure want to move "${this.movedComponent.title}"?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          await Diagram.remote().save({
            ...this.movedComponent,
            folder_id: this.moveFolderId || 0,
          });

          this.$refs.moveComponent.hide();

          this.$router.push({params: {folder_id: this.moveFolderId || 0}});
        } catch (e) {
          this.$q.notify({
            color: 'negative',
            message: e?.error || e?.message || e,
            position: 'top',
            timeout: 3000,
          });
        }
      })
    },
  }
}
</script>

<style lang="scss">

@import "@/styles/quasar.variables.scss";

.component-list {
  .component-widget {
    background: $info;
    color: black
  }

  .component-component {
    background: $primary;
    color: white;
  }

  .component-process {
    background: $secondary;
    color: white;
  }

  .component-function {
    background: $accent;
    color: white;
  }
}

</style>
