<template>
  <div>
    <q-card>
      <q-card-section>
        <template v-for="childField in field.children" :key="childField.id">
          <tree-storage-field
            :field="childField"
            :value="localValue"
            @update="updateValue"
          />
        </template>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import {nanoid} from 'nanoid';

export default {
  name: 'TreeStorageObjectField',

  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      default: () => ({
        id: nanoid(10),
      })
    }
  },

  data() {
    return {
      localValue: this.value || {
        id: nanoid(10),
      }
    }
  },

  watch: {
    value: {
      handler(newVal) {
        this.localValue = newVal || {
          id: nanoid(10),
        }
      },
      deep: true
    }
  },

  methods: {
    updateValue(val) {
      this.$emit('update', val)
    }
  }
}
</script>
