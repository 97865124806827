<template>
  <div class="q-mb-md">
    <media-picker
      v-if="['image', 'sound', 'lottie', 'video'].includes(field.type)"
      :model-value="value"
      @update:model-value="updateValue"
      :product-id="appId"
      :module-id="moduleId"
      :media-type="field.type"
    />
    <q-field v-else-if="field.type === 'bool'">
      <q-checkbox
        :model-value="value"
        :label="field.name"
        @update:model-value="updateValue"
        :true-value="1"
        :false-value="0"
      />
    </q-field>
    <q-input
      v-else
      :model-value="value"
      :label="field.name"
      @update:model-value="updateValue"
      :disable="field.name === 'id'"
    />
  </div>
</template>

<script>
import MediaPicker from '@/components/MediaGallery/MediaPicker.vue';

export default {
  name: 'TreeStorageSimpleField',
  components: {MediaPicker},

  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: [String, Boolean, Number],
      default: ''
    }
  },

  computed: {
    appId() {
      return this.$route.params.app_id;
    },

    moduleId() {
      return this.$route.params.module_id;
    }
  },

  methods: {
    updateValue(val) {
      this.$emit('update', val)
    }
  }
}
</script>
