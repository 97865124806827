<template>
  <q-splitter class="full-height ui-templates-diagrams" v-model="splitter">
    <template #before>
      <div class="q-pa-sm">
        <div class="text-subtitle1">Choose the relevant diagram</div>

        <div class="column q-gutter-md q-mt-sm">
          <q-list v-if="templates.length" bordered separator>
            <q-item
              v-for="diagram in templates"
              :key="diagram.id"
              clickable
              v-ripple
              :active="diagram.id === currentDiagram?.id"
              @click="currentDiagram = diagram"
            >
              <q-item-section>
                <q-item-label>{{ diagram.title }}</q-item-label>
                <q-item-label v-if="diagram.description" caption>{{ diagram.description }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <div v-else class="text-h6">No diagrams found</div>
        </div>
      </div>
    </template>

    <template #after>
      <div class="q-px-md q-pt-sm full-height flex column no-wrap">
        <div class="q-mt-sm" style="flex-basis: 100%">
          <ab-flow-designer
            v-if="currentDiagram"
            :key="currentDiagram?.id"
            ref="diagramPreview"
            class="diagram-preview full-height full-width dg-media"
            style="z-index: 1;"
            :root="currentDiagram.source"
            :componentsList="componentsList"
            :preview-mode="true"
            :canvas_size="{width:1000000, height: 1000000}"
            :product_id="currentDiagram?.id"
          >
            <template #abovecanvas>
              <div class="row q-py-sm q-px-sm">
                <q-space/>

                <q-btn @click="pasteDiagram(currentDiagram.id)" color="primary">Paste diagram</q-btn>
              </div>
            </template>
          </ab-flow-designer>
        </div>
      </div>
    </template>
  </q-splitter>
</template>

<script>
import AbFlowDesigner from 'ab-flow-designer/src/components/Designer/AbFlowDesigner';
import {Diagram} from '../../../../../common/db/Diagram';
import {designerComponentsList} from "@/components/DiagramDesigner/Editor/components/designerComponentsList";
import {shallowRef} from 'vue';
import {AppStyle} from '../../../../../common/db/AppStyle';
import {AppModule} from '../../../../../common/db/AppModule';
import WidgetEditorCmp from '@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetEditorCmp.vue';
import WidgetPropsCmp from '@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetPropsCmp.vue';
import {WidgetProcessor} from '@/components/DiagramDesigner/Editor/components/UI/Containers/Widget/WidgetProcessor';

export default {
  name: 'UiTemplatesDiagramsList',

  components: {AbFlowDesigner},

  emits: ['paste-ui-template'],

  provide: function() {
    return {
      designer: this,
    }
  },

  data() {
    return {
      diagrams: [],
      splitter: 20,
      selectedSection: null,
      templatesProjectId: process.env.VUE_APP_UI_TEMPLATES_MODULE_ID,
      componentsList: [],
      currentDiagram: null,
      styles: null,
    };
  },

  computed: {
    /**
     * Computed property to filter and format diagrams that are templates.
     * It filters diagrams whose titles include '[template]' and removes the '[template]' tag from their titles.
     *
     * @returns {Array} - An array of template diagrams with formatted titles.
     */
    templates() {
      return this.diagrams.filter(
        (diagram) => diagram?.status === 'active'
          && diagram?.diagram_type !== 'widget'
          && diagram?.source
          && (diagram?.title || '').includes('[template]')
      ).map((diagram) => ({
          ...diagram,
          title: diagram.title.replace('[template]', '').replace('[inline]', ''),
        }));
    },

    /**
     * Computed property to get the module ID from the route parameters.
     *
     * @returns {string} - The module ID from the route parameters.
     */
    moduleId() {
      return this.$route.params.module_id;
    },
  },

  methods: {
    /**
     * Emits an event to paste the UI template with the given diagram ID.
     *
     * @param {number} id - The ID of the diagram to be pasted.
     */
    pasteDiagram(id) {
      const diagram = this.diagrams.find(d => d.id === id);

      this.$emit('paste-ui-template', diagram);
    },

    /**
     * Asynchronously loads the styles for the current module.
     *
     * This method fetches the module using the module ID, retrieves its styles from the settings,
     * and then sets the `styles` property with the formatted styles.
     */
    async loadModuleStyles() {
      const module = await AppModule.find(this.moduleId);

      const style = new AppStyle()
      style.source = module?.settings?.styles || {};

      this.styles = style.getStyles();
    },

    /**
     * Defines the components list for the designer.
     *
     * This method filters the diagrams to find those of type 'widget' and maps them to a specific format.
     * It then combines these widgets with the existing designer components list and sets the `componentsList` property.
     */
    defineComponentsList() {
      // Load widgets list
      const widgets = this.diagrams.filter(d => d.diagram_type === 'widget')?.map(d => ({
        title: d.title,
        type: `Widget:${d.id}`,
        component: WidgetEditorCmp,
        properties: WidgetPropsCmp,
        processor: WidgetProcessor
      }));

      // Fill components list according to diagram type
      const list = [...[{
        title: "Widgets",
        type: 'g-widgets',
        purpose: ['ui'],
        expanded: true,
        children: widgets
      }], ...designerComponentsList]

      // Set components list
      this.componentsList = shallowRef(list)
    },

    /**
     * Retrieves a diagram by its ID.
     *
     * @param {number} id - The ID of the diagram to retrieve.
     * @returns {Object|null} - The diagram object if found, otherwise null.
     */
    getDiagram(id) {
      return this.diagrams.find(d => d.id === parseInt(id));
    }
  },

  watch: {
    /**
     * Watcher for the `currentDiagram` property.
     *
     * This watcher triggers when the `currentDiagram` changes. It waits for the next DOM update cycle
     * and then appends a style element with the current styles to the `diagramPreview` element.
     */
    currentDiagram() {
      this.$nextTick(() => {
        if (!this.currentDiagram) {
          return;
        }

        const st = document.createElement("style");
        st.innerHTML = this.styles;
        this.$refs?.diagramPreview?.$el?.appendChild(st);
      });
    },
  },

  async created() {
    if (this.templatesProjectId) {
      this.diagrams = await Diagram.remote().subscribe('app-module-diagrams', {module_id: this.templatesProjectId});

      await this.loadModuleStyles();

      this.defineComponentsList();
    }
  },

  beforeUnmount() {
    if (this.templatesProjectId) {
      Diagram.remote().unsubscribe('app-module-diagrams', {module_id: this.templatesProjectId});
    }
  },
}
</script>

<style lang="scss">
.ui-templates-diagrams {
  .path {
    a {
      color: white;
    }
  }

  .diagram-preview {
    height: calc(100vh - 280px) !important;
  }

  svg {
    overflow: visible;
  }

  .editor-cmp {
    min-height: 1em;
    min-width: 1em;

    outline: 1px dotted transparent;
    border: 1px dashed transparent;

    .mover {
      background: #666;
    }
  }

  .editor-cmp.selected {
    outline: 1px dotted red;
    border: 1px dashed #eee;
  }

  .editor-cmp.hovered {
    border: 1px dashed #0a0 !important;
  }

  .canvas-links {
    overflow: visible;
  }

  .connector-left {
    position: absolute;
    top: 50%;
    left: -15px;
  }

  .connector-right {
    position: absolute;
    top: 50%;
    right: -15px;
  }

  .connector-top {
    position: absolute;
    top: -15px;
    left: 50%;
  }

  .connector-bottom {
    position: absolute;
    bottom: -15px;
    left: 50%;
  }

  .connector-center {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .hide-child-connectors {
    pointer-events: none;
    .link-connector {
      display: none;
    }
  }

}
</style>
