<template>

  <div>
    <ui-prop-string name="title" title="Delay title" :block="block"/>
    <ui-prop-dynamic-string name="delay" title="Delay, ms" :block="block" untranslatable/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  components: {UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "DelayPropsCmp",
  computed: {
  }
}

</script>
