<template>
  <div class="column web-builder full-width full-height" v-if="p3p">
    <q-tab-panels v-model="currentTab" animated class="col">
      <q-tab-panel name="settings" class="col">
        <builder-settings/>
      </q-tab-panel>
      <q-tab-panel name="build" class="col">
        <web-builder-builds/>
      </q-tab-panel>
    </q-tab-panels>

    <div>
      <q-tabs v-model="currentTab" class="bg-grey-3" align="left" >
        <q-tab name="settings" icon="settings" label="Settings"/>
        <q-tab name="build" icon="build" label="Build"/>
      </q-tabs>
    </div>

  </div>
</template>

<script>

//import {Product} from "../models/Product";

import BuilderSettings from "@/pages/p3p/builders/BuilderSettings.vue";
import {p3pMixins} from "@/pages/p3p/p3pMixins";
import WebBuilderBuilds from "@/pages/p3p/builders/WebBuilderBuilds.vue";

export default {
  name: "WebBuilder",
  mixins: [p3pMixins],
  components: {WebBuilderBuilds, BuilderSettings},
  data: () => ({
    currentTab: "settings",
    properties: {},
  }),
}

</script>

<style lang="scss">

.web-builder {
  .q-tab--active {
    background: white;
  }
}

</style>
