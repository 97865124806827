<template>
  <router-view v-if="isReady"/>
</template>

<script>

import {computed} from "vue";
import {P3PService} from "@/pages/p3p/utils/P3PService";
import {P3PNodeService} from "@/pages/p3p/utils/P3PNodeService";

export default {
  name: "P3PTemplate",

  data: () => ({
    p3p: null,
    p3pNode: null,
    token: null,
    isReady: false,
  }),

  provide: function () {
    return {
      p3p: computed(() => this.p3p),
      p3pNode: computed(() => this.p3pNode),
    }
  },

  /**
   * Created
   * @return {Promise<void>}
   */
  async created() {

    // Store token
    this.token = this.$route.params.token

    // Init p3p
    this.p3p = await new P3PService().init()
    this.p3pNode = await new P3PNodeService(this.token).init()

    // Call onInited
    this.isReady = true
  },

}
</script>
